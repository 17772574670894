import React from 'react';
import styles from './UserCollection.module.css';
import {Link} from "react-router-dom";

interface CardComponentProps {
    id: string;
    nomeCollezione: string;
    tipoCollection: string;
    nomeUtente: string;
}

const UserCollection: React.FC<CardComponentProps> = ({
                                                          id,
                                                          nomeCollezione,
                                                          tipoCollection,
                                                          nomeUtente
                                                      }) => {

    return (
        <Link to={`/collections-user-detail/${id}`} className={styles.cardLink}>
        <div className={styles.card}>
            <div className={styles.cardDetails}>
                <h3 className={styles.cardTitle}>{nomeCollezione}</h3>
                <h4 className={styles.cardSubtitle}><em>By {nomeUtente}</em></h4>
                <div className={styles.cardType}>{tipoCollection}</div>
            </div>
        </div>
        </Link>
    );
};

export default UserCollection;
