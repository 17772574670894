import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styles from './HorizontalMenu.module.css';
import logo from '../../assets/logo.png';
import onePieceLogo from '../../assets/one-piece_logo.png';
import dragonBallLogo from '../../assets/logo_dragonball.png';
import pokemonLogo from '../../assets/pokemon_logo.png';
import lorcanaLogo from '../../assets/lorcana_logo.png';
import {Button, Divider, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {FaBell, FaBriefcase, FaChartLine, FaSearch, FaShoppingCart, FaSignInAlt, FaUser} from 'react-icons/fa';
import ButtonGroup from "../Button/ButtonGroup";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

const HorizontalMenu: React.FC = () => {
    const [gamesMenuOpen, setGamesMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [userId, setUserId] = useState('');
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [user, setUser] = useState<any>(null);
    const [gamesMenuAnchorEl, setGamesMenuAnchorEl] = useState<null | HTMLElement>(null); // Stato per il menu Games
    const navigate = useNavigate();

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user') || '{}');
        if (storedUser && storedUser.id) {
            setUser(storedUser);
            setUserId(storedUser.id);
        }
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfileClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setProfileMenuAnchorEl(event.currentTarget);
    };

    const handleProfileClose = () => {
        setProfileMenuAnchorEl(null);
    };

    const handleGamesMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setGamesMenuOpen((prev) => !prev);
        setGamesMenuAnchorEl(event.currentTarget);
    };

    const handleGamesMenuClose = () => {
        setGamesMenuOpen(false);
        setGamesMenuAnchorEl(null);
    };

    const handleMenuItemClick = (game: string, route: string) => {
        setSearchTerm(game);
        setAnchorEl(null);
        navigate(route);
    };

    return (
        <div className={styles.container}>
            <div className={styles.topBar}>
                <div className={styles.logo}>
                    <Link to="/">
                        <img src={logo} alt="CardTrader" className={styles.logoImage} />
                    </Link>
                </div>
                <TextField
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={styles.searchBar}
                    placeholder="Nome carta parziale + espansione, categoria, utenti"
                    variant="outlined"
                    InputProps={{
                        style: { color: 'white', borderRadius: '25px' },
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    aria-label="menu"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    style={{ color: 'white' }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        classes: { notchedOutline: styles.notchedOutline },
                    }}
                />
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                </Menu>

                <div className={styles.iconsContainer}>
                    {user ? (
                        <>
                            <Tooltip title="Notifiche">
                                <div className={styles.icon}><FaBell /></div>
                            </Tooltip>
                            <Tooltip title="Utente">
                                <div
                                    className={styles.icon}
                                    onClick={handleProfileClick}
                                >
                                    <FaUser />
                                </div>
                            </Tooltip>
                            <Menu
                                id="profile-menu"
                                anchorEl={profileMenuAnchorEl}
                                open={Boolean(profileMenuAnchorEl)}
                                onClose={handleProfileClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                            </Menu>
                            <Tooltip title="wishlist">
                                <a href="/wishlist-utente" className={styles.icon}>
                                    <FaBriefcase />
                                </a>
                            </Tooltip>
                            <Tooltip title="Carrello">
                                <div className={styles.icon}><FaShoppingCart /></div>
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            <Tooltip title="Registrati">
                                <Link to="/register" className={styles.icon}><FaSignInAlt /></Link>
                            </Tooltip>
                            <Tooltip title="Login">
                                <Link to="/login" className={styles.icon}><FaUser /></Link>
                            </Tooltip>
                        </>
                    )}
                </div>
            </div>

            <div className={styles.bottomBar}>
                <div className={styles.menu}>
                    <Button
                        onClick={handleGamesMenuClick}
                        endIcon={gamesMenuOpen ? <ExpandLess /> : <ExpandMore />}
                        style={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontFamily: '"Nunito", sans-serif',
                            fontSize: '16px',
                        }}
                    >
                        <FaSearch style={{ marginRight: '8px' }} />
                        ESPLORA I TCG
                    </Button>
                    <Menu
                        anchorEl={gamesMenuAnchorEl}
                        open={Boolean(gamesMenuAnchorEl)}
                        onClose={handleGamesMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', padding: '8px' }}>
                            <div style={{ flex: 1 }}>
                                <MenuItem component={Link} to="/cards/one-piece" onClick={handleGamesMenuClose}>
                                    <img src={onePieceLogo} alt="One Piece" className={styles.gameLogo} />
                                </MenuItem>
                                <Divider />
                                <MenuItem component={Link} to="/cards/pokemon" onClick={handleGamesMenuClose}>
                                    <img src={pokemonLogo} alt="Pokémon" className={styles.gameLogo} />
                                </MenuItem>
                            </div>
                            <Divider orientation="vertical" flexItem style={{ margin: '0 8px' }} />
                            <div style={{ flex: 1 }}>
                                <MenuItem component={Link} to="/cards/dragonball" onClick={handleGamesMenuClose}>
                                    <img src={dragonBallLogo} alt="Dragon Ball" className={styles.gameLogo} />
                                </MenuItem>
                                <Divider />
                                <MenuItem component={Link} to="/cards/lorcana" onClick={handleGamesMenuClose}>
                                    <img src={lorcanaLogo} alt="Lorcana" className={styles.gameLogo} />
                                </MenuItem>
                            </div>
                        </div>
                    </Menu>

                    <Button
                        component={Link}
                        to="/tendenza-prezzi"
                        style={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontFamily: '"Nunito", sans-serif',
                            fontSize: '16px',
                        }}
                    >
                        <FaChartLine style={{ marginRight: '8px' }} />
                        TENDENZA PREZZI
                    </Button>
                </div>

                {user && <ButtonGroup userId={userId} />}
            </div>
        </div>
    );
};

export default HorizontalMenu;