import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Home from './pages/Home';
import ListCard from './pages/ListCard';
import HorizontalMenu from './components/HorizontalMenu/HorizontalMenu';
import './App.css';
import Footer from "./components/Footer/footer";
import MyCollection from "./pages/MyCollection";
import PrivateRoute from "./auth/PrivateRoute";
import {AuthProvider} from './auth/AuthContext';
import Login from "./pages/login";
import MyCollectionDetail from "./pages/detail/MyCollectionDetail";
import ListCollectionsPublic from "./pages/ListCollectionsPublic";
import ListaObiettiviPage from "./pages/ListaObiettiviPage";
import WishlistPage from "./pages/WishlistPage";
import UserProfile from "./pages/UserProfile";
import UserDetail from "./pages/detail/UserDetail";
import TendenzaPrezzoPages from "./pages/TendenzaPrezzo";

const App: React.FC = () => {
    return (
        <AuthProvider>
            <Router>
                <div className="App">
                    <HorizontalMenu />
                    <main>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/cards/:categoryName" element={<ListCard />} />
                            <Route element={<PrivateRoute />}>
                                <Route path="/my-collection/:idUser" element={<MyCollection />} />
                                <Route path="/lista-obiettivi" element={<ListaObiettiviPage />} />
                                <Route path="/my-collection-detail/:idCollection" element={<MyCollectionDetail showExportButton={true}/>} />
                                <Route path="/collections-user/public" element={<ListCollectionsPublic />} />
                                <Route path="/collections-user-detail/:idCollection" element={<MyCollectionDetail showExportButton={false}/>} />
                                <Route path="/wishlist-utente" element={<WishlistPage />} />
                                <Route path="/user-profile" element={<UserProfile />} />
                                <Route path="/user-detail/:idUser" element={<UserDetail />} />
                                <Route path="/user-profile/settings" element={<UserProfile activeMenuItem="settings" />} />
                                <Route path="/tendenza-prezzi" element={<TendenzaPrezzoPages />} />
                            </Route>
                        </Routes>
                    </main>
                    <Footer />
                </div>
            </Router>
        </AuthProvider>
    );
};

export default App;
