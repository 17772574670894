/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Utente } from './Utente';
import {
    UtenteFromJSON,
    UtenteFromJSONTyped,
    UtenteToJSON,
} from './Utente';

/**
 * 
 * @export
 * @interface ConfigurazioniUtente
 */
export interface ConfigurazioniUtente {
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtente
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigurazioniUtente
     */
    collezioneAutomatica?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtente
     */
    nomeCollezioneAutomatica?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigurazioniUtente
     */
    notificheEmail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtente
     */
    tipoInterfaccia?: ConfigurazioniUtenteTipoInterfacciaEnum;
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtente
     */
    preferenzaCollezione?: ConfigurazioniUtentePreferenzaCollezioneEnum;
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtente
     */
    linguaPreferita?: ConfigurazioniUtenteLinguaPreferitaEnum;
    /**
     * 
     * @type {number}
     * @memberof ConfigurazioniUtente
     */
    elementiPerPagina?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigurazioniUtente
     */
    notificaObiettivoCompletato?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigurazioniUtente
     */
    backupAutomatico?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtente
     */
    frequenzaBackup?: ConfigurazioniUtenteFrequenzaBackupEnum;
    /**
     * 
     * @type {string}
     * @memberof ConfigurazioniUtente
     */
    tipoFormatoBackup?: ConfigurazioniUtenteTipoFormatoBackupEnum;
    /**
     * 
     * @type {Utente}
     * @memberof ConfigurazioniUtente
     */
    utente?: Utente;
}


/**
 * @export
 */
export const ConfigurazioniUtenteTipoInterfacciaEnum = {
    Dark: 'DARK',
    Classic: 'CLASSIC'
} as const;
export type ConfigurazioniUtenteTipoInterfacciaEnum = typeof ConfigurazioniUtenteTipoInterfacciaEnum[keyof typeof ConfigurazioniUtenteTipoInterfacciaEnum];

/**
 * @export
 */
export const ConfigurazioniUtentePreferenzaCollezioneEnum = {
    Griglia: 'GRIGLIA',
    Lista: 'LISTA'
} as const;
export type ConfigurazioniUtentePreferenzaCollezioneEnum = typeof ConfigurazioniUtentePreferenzaCollezioneEnum[keyof typeof ConfigurazioniUtentePreferenzaCollezioneEnum];

/**
 * @export
 */
export const ConfigurazioniUtenteLinguaPreferitaEnum = {
    Italiano: 'ITALIANO',
    English: 'ENGLISH',
    Espanol: 'ESPANOL'
} as const;
export type ConfigurazioniUtenteLinguaPreferitaEnum = typeof ConfigurazioniUtenteLinguaPreferitaEnum[keyof typeof ConfigurazioniUtenteLinguaPreferitaEnum];

/**
 * @export
 */
export const ConfigurazioniUtenteFrequenzaBackupEnum = {
    Settimanale: 'SETTIMANALE',
    Mensile: 'MENSILE',
    Trimestrale: 'TRIMESTRALE',
    Semestrale: 'SEMESTRALE',
    Annuale: 'ANNUALE'
} as const;
export type ConfigurazioniUtenteFrequenzaBackupEnum = typeof ConfigurazioniUtenteFrequenzaBackupEnum[keyof typeof ConfigurazioniUtenteFrequenzaBackupEnum];

/**
 * @export
 */
export const ConfigurazioniUtenteTipoFormatoBackupEnum = {
    Xlsx: 'XLSX',
    Pdf: 'PDF'
} as const;
export type ConfigurazioniUtenteTipoFormatoBackupEnum = typeof ConfigurazioniUtenteTipoFormatoBackupEnum[keyof typeof ConfigurazioniUtenteTipoFormatoBackupEnum];


/**
 * Check if a given object implements the ConfigurazioniUtente interface.
 */
export function instanceOfConfigurazioniUtente(value: object): value is ConfigurazioniUtente {
    return true;
}

export function ConfigurazioniUtenteFromJSON(json: any): ConfigurazioniUtente {
    return ConfigurazioniUtenteFromJSONTyped(json, false);
}

export function ConfigurazioniUtenteFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurazioniUtente {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'collezioneAutomatica': json['collezioneAutomatica'] == null ? undefined : json['collezioneAutomatica'],
        'nomeCollezioneAutomatica': json['nomeCollezioneAutomatica'] == null ? undefined : json['nomeCollezioneAutomatica'],
        'notificheEmail': json['notificheEmail'] == null ? undefined : json['notificheEmail'],
        'tipoInterfaccia': json['tipoInterfaccia'] == null ? undefined : json['tipoInterfaccia'],
        'preferenzaCollezione': json['preferenzaCollezione'] == null ? undefined : json['preferenzaCollezione'],
        'linguaPreferita': json['linguaPreferita'] == null ? undefined : json['linguaPreferita'],
        'elementiPerPagina': json['elementiPerPagina'] == null ? undefined : json['elementiPerPagina'],
        'notificaObiettivoCompletato': json['notificaObiettivoCompletato'] == null ? undefined : json['notificaObiettivoCompletato'],
        'backupAutomatico': json['backupAutomatico'] == null ? undefined : json['backupAutomatico'],
        'frequenzaBackup': json['frequenzaBackup'] == null ? undefined : json['frequenzaBackup'],
        'tipoFormatoBackup': json['tipoFormatoBackup'] == null ? undefined : json['tipoFormatoBackup'],
        'utente': json['utente'] == null ? undefined : UtenteFromJSON(json['utente']),
    };
}

export function ConfigurazioniUtenteToJSON(value?: ConfigurazioniUtente | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'collezioneAutomatica': value['collezioneAutomatica'],
        'nomeCollezioneAutomatica': value['nomeCollezioneAutomatica'],
        'notificheEmail': value['notificheEmail'],
        'tipoInterfaccia': value['tipoInterfaccia'],
        'preferenzaCollezione': value['preferenzaCollezione'],
        'linguaPreferita': value['linguaPreferita'],
        'elementiPerPagina': value['elementiPerPagina'],
        'notificaObiettivoCompletato': value['notificaObiettivoCompletato'],
        'backupAutomatico': value['backupAutomatico'],
        'frequenzaBackup': value['frequenzaBackup'],
        'tipoFormatoBackup': value['tipoFormatoBackup'],
        'utente': UtenteToJSON(value['utente']),
    };
}

