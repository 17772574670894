import React, { createContext, useState, useEffect, ReactNode, FC, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {LoginRequest, UtenteDTO, UtenteRestApi} from '../api';

interface AuthContextType {
    user: UtenteDTO | null;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
    loading: boolean;
}

const api = new UtenteRestApi();
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<UtenteDTO | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const loggedUser = localStorage.getItem('user');
        if (loggedUser) {
            setUser(JSON.parse(loggedUser));
        }
        setLoading(false);
    }, []);

    const login = async (username: string, password: string) => {
        const loginRequest: LoginRequest = {
            loginRequestDTO: {
                username,
                password,
            },
        };

        try {
            const response = await api.loginRaw(loginRequest);
            const data = await response.value();
            setUser(data);
            localStorage.setItem('user', JSON.stringify(data));
            toast.success('Login eseguito correttamente!');
        } catch (error) {
            toast.error('Login Fallito!');
        }
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('user');
        toast.info('Sei stato disconnesso!');
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {children}
            <ToastContainer />
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
