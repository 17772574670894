import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import styles from './tendenza-prezzo.module.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TendenzaPrezzo = ({ tendenza }: { tendenza: any }) => {
    const [prices, setPrices] = useState<number[]>([]);
    const [dates, setDates] = useState<string[]>([]);
    const [filteredPrices, setFilteredPrices] = useState<number[]>([]);
    const [filteredDates, setFilteredDates] = useState<string[]>([]);

    useEffect(() => {
        if (tendenza && tendenza.length > 0) {
            const combinedPrices = tendenza.map((line: { normale: string, foil: string, prezzoChiusura: string }) => {
                return line.normale ? parseFloat(line.normale) :
                    (line.foil ? parseFloat(line.foil) :
                        (line.prezzoChiusura ? parseFloat(line.prezzoChiusura) : 0));
            });

            const extractedDates = tendenza.map((line: { data: string }) =>
                line.data ? new Date(parseInt(line.data) * 1000).toLocaleDateString() : ''
            );

            setPrices(combinedPrices);
            setDates(extractedDates);
            setFilteredPrices(combinedPrices);
            setFilteredDates(extractedDates);
        }
    }, [tendenza]);

    // Funzione per filtrare i dati per intervallo di date
    const filterDataByRange = (range: '3m' | '6m' | '1y') => {
        const today = new Date();
        let startDate: Date;

        switch (range) {
            case '3m':
                startDate = new Date(today.setMonth(today.getMonth() - 3));
                break;
            case '6m':
                startDate = new Date(today.setMonth(today.getMonth() - 6));
                break;
            case '1y':
                startDate = new Date(today.setFullYear(today.getFullYear() - 1));
                break;
            default:
                startDate = new Date(0); // Nessun filtro
        }

        const filteredDates = dates.filter(date => new Date(date) >= startDate);
        const startIndex = dates.length - filteredDates.length;
        const filteredPrices = prices.slice(startIndex);

        setFilteredDates(filteredDates);
        setFilteredPrices(filteredPrices);
    };

    const data = {
        labels: filteredDates,
        datasets: [
            {
                label: 'Prezzo',
                data: filteredPrices,
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                tension: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top' as const,
            },
            tooltip: {
                callbacks: {
                    label: (context: any) => `€${context.raw.toFixed(2)}`,
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Tendenza Prezzo',
                    color: '#FF5733',
                    font: {
                        size: 16,
                        weight: 'bold' as const,
                    },
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Prezzo (€)',
                    color: '#FF5733',
                    font: {
                        size: 16,
                        weight: 'bold' as const,
                    },
                    padding: {
                        top: 20,
                        bottom: 10,
                    },
                },
                beginAtZero: false,
            },
        },
    } as const;

    return (
        <div style={{ position: 'relative' }}>
            <div className={styles.buttonContainer}>
                <button className={styles.bottoni} onClick={() => filterDataByRange('3m')}>Ultimi 3 mesi</button>
                <button className={styles.bottoni} onClick={() => filterDataByRange('6m')}>Ultimi 6 mesi</button>
                <button className={styles.bottoni} onClick={() => filterDataByRange('1y')}>Ultimo anno</button>
            </div>

            <div className={styles.chartContainer} style={{ width: '70%', height: '70%', margin: '0 auto', paddingTop: '50px' }}>
                <Line data={data} options={options} />
            </div>
        </div>
    );
};

export default TendenzaPrezzo;
