import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import CardList from '../components/Card/Lista/Card-list';
import FilterAndSort from '../components/Card/Filtri-ricerca/filtri-ricerca';
import '../styles/CardsPage.css';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import TCGHeader from "../components/HeaderImg/HeaderImg";
import {CircularProgress} from "@mui/material";
import {useDispatch, useSelector} from 'react-redux';
import {fetchCards, setCurrentPage} from "../store/carte/CarteSlice";
import {AppDispatch, RootState} from "../store/store";


const theme = createTheme({
    components: {
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: '#3f51b5',
                    '&:hover': {
                        backgroundColor: '#303f9f',
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#1a237e',
                    },
                },
            },
        },
    },
});

const ListCard: React.FC = () => {
    const { categoryName } = useParams<{ categoryName: string }>();
    const dispatch = useDispatch<AppDispatch>();

    const cards = useSelector((state: RootState) => state.carte.cards);
    const totalPages = useSelector((state: RootState) => state.carte.totalPages);
    const currentPage = useSelector((state: RootState) => state.carte.currentPage);
    const error = useSelector((state: RootState) => state.carte.error);
    const isLoading = useSelector((state: RootState) => state.carte.isLoading);

    const [filter, setFilter] = useState<any>({});

    useEffect(() => {
        if (categoryName) {
            dispatch(fetchCards({ categoryName, newFilter: filter, page: currentPage }));
        }
    }, [categoryName, currentPage, filter, dispatch]);

    const handleFilter = (newFilter: any) => {
        setFilter(newFilter);
        dispatch(setCurrentPage(1));
        dispatch(fetchCards({ categoryName, newFilter, page: 1 }));
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        dispatch(setCurrentPage(page));
        dispatch(fetchCards({ categoryName, newFilter: filter, page }));
    };
    return (
        <div className="cards-page">
            <TCGHeader category={categoryName || 'default'} />
            <div className="content">
                <div className="filters">
                    <h2>Filtri Di Ricerca</h2>
                    {error && <p className="error">{error}</p>}
                    <FilterAndSort
                        onFilter={handleFilter}
                        isCollection={false}
                    tipoCard={categoryName}/>
                </div>
                <div className="card-list-container">
                    {isLoading ? (
                        <div className="loading-spinner">
                            <CircularProgress />
                        </div>
                    ) : (
                        <CardList
                            filter={filter}
                            cards={cards}
                            tipoCard={categoryName} />
                    )}
                    <ThemeProvider theme={theme}>
                        <Stack spacing={2} alignItems="center" sx={{ marginTop: '20px' }}>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                variant="outlined"
                                shape="rounded"
                                renderItem={(item) => <PaginationItem {...item} />}
                            />
                        </Stack>
                    </ThemeProvider>
                </div>
            </div>
        </div>
    );
};

export default ListCard;
