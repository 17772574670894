import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button, Card, CardContent, Tooltip, Typography} from '@mui/material';
import {FaPlus} from 'react-icons/fa';
import {styled} from '@mui/material/styles';
import styles from '../styles/MyCollection.module.css';
import TabellaMiaCollezione from "../components/Collection/Tabella-mia-collezione";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import {fetchCollezioni} from "../store/collezioni/CollezioniSlice";
import {Person} from "@mui/icons-material";
// import CreateCollection from "../components/Dialog/CreateCollection/Create-collection";
// import EditCollection from "../components/Dialog/EditCollection/Edit-collection";
// import DeleteCollection from "../components/Dialog/DeleteCollection/Delete-collection";
import CreateCollection from '../components/dialog/CreateCollection/Create-collection';
import EditCollection from "../components/dialog/EditCollection/Edit-collection";
import DeleteCollection from "../components/dialog/DeleteCollection/Delete-collection";



const CustomButton = styled(Button)(({ theme }) => ({
    textAlign: 'center',
    borderRadius: '50px',
    padding: '12px 24px',
    backgroundColor: 'green',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 'bold',
    color: 'white',
    fontSize: '1.2rem',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    '&:hover': {
        backgroundColor: '#0056a3',
        transform: 'translateY(-2px)',
    },
    '&:active': {
        transform: 'translateY(0)',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    },
}));

export const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: "50px",
    borderRadius: '35px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
}));

const MyCollection: React.FC = () => {
    const { idUser } = useParams<{ idUser: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const collections = useSelector((state: RootState) => state.collezioni.collezioni);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [selectedCollectionId, setSelectedCollectionId] = useState<any>(undefined);
    const [selectedNomeCollection, setSelectedNomeCollection] = useState<any>(undefined);

    useEffect(() => {
        dispatch(fetchCollezioni({ idUtente: idUser }))

            .unwrap()
            .then(() => setLoading(false))
            .catch((err) => {
                setError(err.message);
                setLoading(false);
            });
    }, [dispatch, idUser]);

    const handleOpenCreateDialog = () => {
        setSelectedCollectionId(undefined);
        setOpenCreateDialog(true);
    };

    const handleCloseCreateDialog = () => {
        setOpenCreateDialog(false);
    };

    const handleOpenEditDialog = (id: string) => {
        const collectionToEdit = collections.find(collezione => collezione.id === id);
        setSelectedCollectionId(id);
        setSelectedNomeCollection(collectionToEdit?.nome);
        setOpenEditDialog(true);
    };

    const handleOpenDeleteDialog = (id: string) => {
        const collectionToDelete = collections.find(collezione => collezione.id === id);
        setSelectedCollectionId(id);
        setSelectedNomeCollection(collectionToDelete?.nome);
        setOpenDeleteDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setSelectedCollectionId(undefined);
        dispatch(fetchCollezioni({ idUtente: idUser }))
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedCollectionId(undefined);
        setSelectedNomeCollection(undefined);
    };

    const handleEditClick = (id: string) => {
        handleOpenEditDialog(id);
    };

    const handleDeleteClick = (id: string) => {
        handleOpenDeleteDialog(id);
    };

    if (loading) {
        return <div className={styles.loading}>Loading...</div>;
    }

    if (error) {
        return <div className={styles.error}>{error}</div>;
    }

    return (
        <div style={{
            padding: '20px',
            marginTop: "20px",
            marginBottom: "50px" }}>
            <StyledCard variant="outlined" style={{
                marginBottom: '20px'
            }}>
                <CardContent style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center' }}>
                    <Typography variant="h4" style={{
                        fontWeight: 'bold',
                        fontSize: '2em',
                        fontFamily: '"Montserrat", sans-serif',
                        textTransform: "uppercase",
                        marginRight: "20px",
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Person style={{
                            marginRight: '30px',
                            fontSize: '1.7em' }} />
                        Le Mie Collezioni
                    </Typography>
                    <Tooltip title="Crea nuova collezione">
                        <CustomButton
                            variant="contained"
                            startIcon={<FaPlus />}
                            onClick={handleOpenCreateDialog}
                        >
                            Nuova
                        </CustomButton>
                    </Tooltip>
                </CardContent>
            </StyledCard>
            <TabellaMiaCollezione
                rows={collections}
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteClick} />
            <CreateCollection
                open={openCreateDialog}
                onClose={handleCloseCreateDialog}
                userId={idUser}
            />
            <EditCollection
                open={openEditDialog}
                onClose={handleCloseEditDialog}
                collectionId={selectedCollectionId}
            />
            <DeleteCollection
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                collectionId={selectedCollectionId}
                nome={selectedNomeCollection} />
        </div>
    );
};

export default MyCollection;
