import React from 'react';
import { IconButton, Grid, Typography, Tooltip, Paper } from '@mui/material';
import { FaEdit, FaEye, FaLock, FaTrash } from 'react-icons/fa';
import { transformCardType } from '../../utility/utils';
import { useNavigate } from 'react-router-dom';
import { TabellaMiaCollezioneProps } from '../../utility/props';

const headers = [
    { id: 'nome', label: 'NOME', xs: 3 },
    { id: 'privacy', label: 'PRIVACY', xs: 3 },
    { id: 'tipoCollezione', label: 'TIPO', xs: 3 },
    { id: 'azioni', label: 'AZIONI', xs: 3 },
];

const TabellaMiaCollezione: React.FC<TabellaMiaCollezioneProps> = ({ rows, onEditClick, onDeleteClick }) => {
    const navigate = useNavigate();

    const handleViewClick = (id: string | undefined) => {
        navigate(`/my-collection-detail/${id}`);
    };

    return (
        <Paper elevation={0} sx={{ borderRadius: 2, maxWidth: '800px', margin: 'auto' }}>
            <Grid container sx={{ borderBottom: '2px solid #e0e0e0', backgroundColor: '#f9f9f9' }}>
                {headers.map(header => (
                    <Grid item xs={header.xs} key={header.id} sx={{ padding: 2, textAlign: 'center', fontFamily: '"Montserrat", sans-serif' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontFamily: '"Montserrat", sans-serif'  }}>
                            {header.label}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
            {rows.map((row, index) => (
                <Grid
                    container
                    key={index}
                    sx={{
                        padding: 1,
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: '1px solid #e0e0e0',
                        '&:hover': {
                            backgroundColor: '#e0f7fa',
                        },
                    }}
                >
                    <Grid item xs={3} sx={{ padding: 1, textAlign: 'center', backgroundColor: 'transparent', fontFamily: '"Montserrat", sans-serif' }}>{row.nome}</Grid>
                    <Grid item xs={3} sx={{ padding: 1, textAlign: 'center', backgroundColor: 'transparent' }}>
                        {row.isPrivate !== undefined ? (
                            row.isPrivate ? (
                                <Tooltip title="Privata">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <FaLock style={{ marginRight: 4 }} />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Pubblica">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <FaEye style={{ marginRight: 4 }} />
                                    </div>
                                </Tooltip>
                            )
                        ) : 'N/A'}
                    </Grid>
                    <Grid item xs={3} sx={{ padding: 1, textAlign: 'center', backgroundColor: 'transparent', fontFamily: '"Montserrat", sans-serif' }}>{transformCardType(row.tipoCard)}</Grid>
                    <Grid item xs={3} sx={{ padding: 1, textAlign: 'center', backgroundColor: 'transparent', fontFamily: '"Montserrat", sans-serif' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
                            <Tooltip title="Visualizza">
                                <IconButton onClick={() => handleViewClick(row.id)} sx={{ color: '#3f51b5' }}>
                                    <FaEye />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Modifica">
                                <IconButton onClick={() => onEditClick(row.id!)} sx={{ color: 'chocolate' }}>
                                    <FaEdit />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Elimina">
                                <IconButton onClick={() => onDeleteClick(row.id!, row.nome!)} sx={{ color: 'crimson' }}>
                                    <FaTrash />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
            ))}
        </Paper>
    );
};

export default TabellaMiaCollezione;
