import React, { useState, useEffect } from 'react';
import TendenzaPrezzo from "../components/Grafici/TendenzaPrezzo";
import {
    Autocomplete,
    TextField,
    Box,
    CircularProgress,
    debounce,
    Typography,
    CardMedia,
    Card,
    CardContent, CardActions, Button
} from '@mui/material';
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/store";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { CarteRestApi, TipoCardFilterEqualsEnum } from "../api";


const api = new CarteRestApi();

const TendenzaPrezzoPages: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [selectedCard, setSelectedCard] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [tendenza, setTendenza] = useState<any | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [prevSelectedCardId, setPrevSelectedCardId] = useState<string | null>(null);

    const handleSearch = debounce(async (event: React.SyntheticEvent, value: string) => {
        setSearchTerm(value);

        if (value.trim() === '') {
            setSuggestions([]);
            return;
        }

        if (value.length >= 4) {
            setLoading(true);
            try {
                const pageable = { pageNumber: currentPage - 1, pageSize: 15 };
                const newFilter = {
                    criteria: { tipoCard: { equals: TipoCardFilterEqualsEnum.OnePiece } },
                    criteriaOnePiece: { numeroEspansione: { contains: value } },
                    pageable
                };
                const searchRequest = { searchRequest: newFilter };
                const response = await api.findByCriteriaSpecificCardRaw(searchRequest);
                const data = await response.raw.json();
                if (data?.content && Array.isArray(data.content)) {
                    setSuggestions(data.content);
                } else {
                    setSuggestions([]);
                }
            } catch (error) {
                console.error('Errore nella ricerca delle carte:', error);
                setSuggestions([]);
                alert('Errore nel recupero delle carte. Riprova più tardi!');
            } finally {
                setLoading(false);
            }
        } else {
            setSuggestions([]);
        }
    }, 500);



    const handleSelectCard = async (newCard: any) => {
        setLoading(true);
        try {
            if (newCard && newCard.id !== prevSelectedCardId) {
                setSelectedCard(newCard);
                setPrevSelectedCardId(newCard.id);
                setTendenza(newCard.tendenzaPrezzo);
            }
        } catch (error) {
            console.error('Errore nel recuperare i dettagli della carta:', error);
            alert('Errore nel recupero dei dettagli della carta. Riprova più tardi!');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        if (selectedCard?.id && selectedCard.id !== prevSelectedCardId) {
            setPrevSelectedCardId(selectedCard.id);
            handleSelectCard(selectedCard).then(r => { });
        }
    }, [suggestions, selectedCard, tendenza]);

    return (
        <div style={{ padding: '30px' }}>
            <Card sx={{
                marginBottom: '30px',
                boxShadow: 3,
                borderRadius: '18px',
                padding: '20px',
                backgroundColor: '#f5f5f5',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px auto'
            }}>
                <Typography
                    variant="h4"
                    component="div"
                    fontWeight="bold"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#3f51b5',
                        fontFamily: 'Roboto, sans-serif',
                        textTransform: 'uppercase',
                        letterSpacing: '2px',
                    }}
                >
                    <TrendingUpIcon sx={{ marginRight: '18px' }} />
                    Tendenza Prezzi
                </Typography>
            </Card>
            <Autocomplete
                options={suggestions}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                filterOptions={(options) => options}
                sx={{
                    width: 400,
                    marginBottom: '20px',
                    margin: '20px auto',
                    boxShadow: 3,
                    borderRadius: '8px',
                }}
                value={selectedCard || null}
                onInputChange={(event, value) => handleSearch(event, value)}
                onChange={(event, newValue) => setSelectedCard(newValue || null)}
                loading={loading}
                renderInput={(params) => (
                    <TextField {...params} label="Cerca carta per Numero Espansione" variant="outlined" />
                )}
                renderOption={(props, option) => (
                    <li
                        {...props}
                        key={option.id}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '12px',
                            cursor: 'pointer',
                            borderBottom: '1px solid #e0e0e0',
                        }}
                    >
                        <img
                            src={option.imageUrl}
                            alt={option.name}
                            style={{
                                width: '50px',
                                height: '50px',
                                objectFit: 'cover',
                                marginRight: '16px',
                                borderRadius: '4px',
                            }}
                        />
                        <Box display="flex" flexDirection="column">
                            <Typography variant="body1" fontWeight="bold">
                                {option.name} <em>({option.numeroEspansione})</em>
                                {option.alternativeArt && <span> - {option.alternativeArt}</span>}
                            </Typography>
                            {option.sourceName && (
                                <Typography variant="caption" style={{ color: '#757575' }}>
                                    {option.sourceName}
                                </Typography>
                            )}
                        </Box>
                    </li>
                )}
                noOptionsText="Nessun risultato trovato"
                loadingText="Caricamento..."
            />

            {loading && <CircularProgress style={{ marginTop: '20px' }} />}

            {selectedCard && (
                <Card sx={{ display: 'flex', marginTop: '20px', marginBottom: '20px', padding: '20px', boxShadow: 3, maxWidth: '800px', margin: '30px auto' }}>
                    <Box sx={{ flexShrink: 0 }}>
                        <CardMedia
                            component="img"
                            image={selectedCard?.imageUrl}
                            alt={selectedCard?.name}
                            sx={{
                                width: 200,
                                height: 300,
                                objectFit: 'cover',
                                borderRadius: '8px',
                            }}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="space-between" ml={3} flex={1}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                component="div"
                                fontWeight="bold"
                                sx={{ marginBottom: '16px', fontFamily: '"Montserrat", sans-serif' }}
                            >
                                {selectedCard?.name} <em>({selectedCard?.numeroEspansione})</em>
                                {selectedCard?.alternativeArt && <span> - {selectedCard?.alternativeArt}</span>}
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                gutterBottom
                                sx={{ fontFamily: '"Nunito", sans-serif' }}
                            >
                                <strong>Rarità:</strong> {selectedCard?.rarity}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ fontFamily: '"Nunito", sans-serif' }}
                            >
                                <strong>Espansione:</strong> {selectedCard?.sourceName}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ fontFamily: '"Nunito", sans-serif' }}
                            >
                                <strong>Collezionata </strong> {selectedCard?.collezionisti} volte
                            </Typography>
                        </CardContent>

                        <CardActions>
                            <Button variant="contained" color="primary">
                                Dettagli
                            </Button>
                            <Button variant="outlined" color="secondary">
                                Aggiungi ai preferiti
                            </Button>
                        </CardActions>
                    </Box>
                </Card>
            )}

            {selectedCard && <TendenzaPrezzo tendenza={tendenza} />}
        </div>
    );
};
export default TendenzaPrezzoPages;
