import {
    CollectionByIdRequest,
    CollectionByIdUtenteRequest,
    CollezioneDTO,
    CollezioneRestApi, type CollezioneUpdateDTO,
    CreateCollectionRequest,
    DeleteCollectionRequest,
    UpdateCollectionRequest
} from "../../api";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toast} from "react-toastify";

interface CollezioniState {
    collezioni: CollezioneDTO[];
    loading: boolean;
    error: string | null;
    selectedCollezione: CollezioneDTO | null;
}

const initialState: CollezioniState = {
    collezioni: [],
    loading: false,
    error: null,
    selectedCollezione: null,
};
const api = new CollezioneRestApi();

// Fetch Collezioni
export const fetchCollezioni = createAsyncThunk(
    'collezioni/fetchCollezioni',
    async ({idUtente}: { idUtente: string | undefined; }, {rejectWithValue}) => {
        if (!idUtente) return rejectWithValue('ID Utente mancante');


        const apiRequest: CollectionByIdUtenteRequest = {
            userId: idUtente,
        };


        try {
            const response = await api.collectionByIdUtenteRaw(apiRequest);
            const jsonResponse = await response.raw.json();
            return jsonResponse || [];
        } catch (error) {
            toast.error('Si è verificato un errore con il caricamento dei commenti.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

export const fetchCollezionById = createAsyncThunk(
    'collezioni/fetchCollezionById',
    async ({idCollection}: { idCollection: string | undefined; }, {rejectWithValue}) => {
        if (!idCollection) return rejectWithValue('ID Utente mancante');


        const apiRequest: CollectionByIdRequest = {
            collectionId: idCollection,
        };


        try {
            const response = await api.collectionByIdRaw(apiRequest);
            const jsonResponse = await response.raw.json();
            return jsonResponse || null;
        } catch (error) {
            toast.error('Si è verificato un errore con il caricamento dei commenti.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

//ADD COLLECTION

export const addCollection = createAsyncThunk(
    'collezioni/addCollection',
    async (collezione: CollezioneDTO, {rejectWithValue}) => {
        const createRequest: CreateCollectionRequest = {
            collezioneInsertDTO: collezione
        };
        try {
            const response = await api.createCollectionRaw(createRequest);
            return await response.raw.json();
        } catch (error) {
            toast.error('Si è verificato un errore durante la creazione della collezione.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

// Update Comment
export const updateCollection = createAsyncThunk(
    'collezioni/updateCollection',
    async (collezione: CollezioneDTO, {rejectWithValue}) => {
        const updateRequest: UpdateCollectionRequest = {
            collezioneUpdateDTO: collezione
        };

        try {
            const response = await api.updateCollectionRaw(updateRequest);
            return collezione;
        } catch (error) {
            toast.error('Si è verificato un errore con l\'aggiornamento del commento.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

export const deleteCollection = createAsyncThunk(
    'collezioni/deleteCollection',
    async (id: string | undefined, {rejectWithValue}) => {
        if (!id) return rejectWithValue('ID collezione mancante');
        const request: DeleteCollectionRequest = {
            idCollection: id
        };

        try {
            await api.deleteCollection(request);
            toast.success('Collezione eliminata con successo!');
            return id;
        } catch (error) {
            toast.error('Si è verificato un errore con l\'aggiornamento del commento.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

// Upload Image Cover
export const uploadImageCover = createAsyncThunk(
    'collezioni/uploadImageCover',
    async ({ idCollection, file }: { idCollection: string | undefined; file: File | null }, { rejectWithValue, dispatch }) => {
        if (!idCollection) return rejectWithValue('ID collezione mancante');

        try {
            if (file && file.size > 0) {
                const formData = new FormData();
                formData.append('file', file);

                const response = await fetch(`http://127.0.0.1:8080/api/collection/${idCollection}/cover`, {
                    method: 'POST',
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error('Errore durante il caricamento dell\'immagine');
                }

                const updatedCollection = await response.json();

                return updatedCollection;
            } else {
                const updateDTO: CollezioneUpdateDTO = {
                    id: idCollection,
                    imgCopertina: undefined,
                    modificaCopertina: true
                };


                const updatedCollection = await dispatch(updateCollection(updateDTO)).unwrap();

                return updatedCollection;
            }
        } catch (error) {
            toast.error('Si è verificato un errore durante il caricamento dell\'immagine di copertura.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);








const collezioniSlice = createSlice({
    name: 'collezioni',
    initialState,
    reducers: {
        setCollezioni: (state, action: PayloadAction<CollezioneDTO[]>) => {
            state.collezioni = action.payload;
        },
        setSelectedCollezione: (state, action: PayloadAction<CollezioneDTO | null>) => {
            state.selectedCollezione = action.payload;
        },
        updateCollectionReducer: (state, action: PayloadAction<CollezioneDTO>) => {
            const index = state.collezioni.findIndex(collezione => collezione.id === action.payload.id);
            if (index !== -1) {
                state.collezioni[index] = action.payload;
            }
        },
        deleteCollectionReducer: (state, action: PayloadAction<number | undefined>) => {
            state.collezioni = state.collezioni.filter(comment => comment.id !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCollezioni.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCollezioni.fulfilled, (state, action) => {
                state.loading = false;
                state.collezioni = action.payload;
            })
            .addCase(fetchCollezioni.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(fetchCollezionById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCollezionById.fulfilled, (state, action) => {
                state.loading = false;
                state.selectedCollezione = action.payload;
            })
            .addCase(fetchCollezionById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(addCollection.fulfilled, (state, action) => {
                state.loading = false;
                state.collezioni.push(action.payload);
            })
            .addCase(updateCollection.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateCollection.fulfilled, (state, action) => {
                state.loading = false;
                const index = state.collezioni.findIndex(comment => comment.id === action.payload.id);
                if (index !== -1) {
                    state.collezioni[index] = action.payload;
                }
            })
            .addCase(updateCollection.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(deleteCollection.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteCollection.fulfilled, (state, action) => {
                state.loading = false;
                state.collezioni = state.collezioni.filter(comment => comment.id !== action.payload);
            })
            .addCase(deleteCollection.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(uploadImageCover.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(uploadImageCover.fulfilled, (state, action) => {
                state.loading = false;
                // Handle any state update if needed after successful image upload
            })
            .addCase(uploadImageCover.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    }
});
export const {
    setCollezioni,
    setSelectedCollezione,
    deleteCollectionReducer,
    updateCollectionReducer,
} = collezioniSlice.actions;
export default collezioniSlice.reducer;
