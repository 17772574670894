import {Box, Button, Card, CardContent, LinearProgress, Tooltip, Typography} from '@mui/material';
import React, {useState} from 'react';
import './obiettivi.module.css';
import {GameObjectivesCardProps} from '../../utility/props';


const Obiettivi: React.FC<GameObjectivesCardProps> = ({ objectives }) => {
    const [showAll, setShowAll] = useState(false);

    const calcolaProgresso = (progresso: number, target: number): number => {
        if (!progresso || !target) {
            return 0;
        }
        return (progresso / target) * 100;
    };

    const displayedObjectives = showAll ? objectives : objectives.slice(0, 5);

    return (
        <Card sx={{ width: 400, padding: 2 }}>
            <CardContent>
                <Typography variant="h5" component="div" gutterBottom align="center">
                    Progressi Obiettivi
                </Typography>
                {displayedObjectives.map((objective, index) => {
                    const progressoCalcolato = calcolaProgresso(objective.progresso ?? 0, objective.obiettivo?.target ?? 1);

                    const obiettivoNome = objective.obiettivo ? objective.obiettivo.nome : 'Obiettivo non disponibile';
                    const descrizioneObj = objective.obiettivo ? objective.obiettivo.descrizione : 'Obiettivo non disponibile';

                    return (
                        <Box key={index} className="objective-card" sx={{ marginBottom: 3 }}>
                            <Tooltip title={obiettivoNome} arrow>
                                <Typography variant="h6" component="div" gutterBottom>
                                    {descrizioneObj}
                                </Typography>
                            </Tooltip>
                            <LinearProgress
                                variant="determinate"
                                value={progressoCalcolato}
                                sx={{ height: 10, borderRadius: 5 }}
                            />
                            <Typography variant="body2" sx={{ marginTop: 1, textAlign: 'right' }}>
                                {progressoCalcolato.toFixed(0)}%
                            </Typography>
                        </Box>
                    );
                })}

                {objectives.length > 5 && (
                    <Box sx={{ marginTop: 2, textAlign: 'center' }}>
                        <Button
                            onClick={() => setShowAll(!showAll)}
                            variant="outlined"
                            sx={{ textTransform: 'none' }}
                        >
                            {showAll ? 'Visualizza Meno' : 'Visualizza Tutti'}
                        </Button>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default Obiettivi;
