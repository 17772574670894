import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import FilterAndSort from '../components/Card/Filtri-ricerca/filtri-ricerca';
import '../styles/CollectionsPublic.css';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import {CollezioneRestApi, PubblicCollectionRequest} from '../api';
import {Card, CardContent, CircularProgress, Typography} from "@mui/material";
import ListaCollezioniPubbliche from "../components/Collection/Lista-collezioni-pubbliche";
import {People} from '@mui/icons-material';

const api = new CollezioneRestApi();

export const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: "50px",
    borderRadius: '35px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
}));

const theme = createTheme({
    components: {
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: '#3f51b5',
                    '&:hover': {
                        backgroundColor: '#303f9f',
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#1a237e',
                    },
                },
            },
        },
    },
});

const ListCollectionsPublic: React.FC = () => {
    const { categoryName } = useParams<{ categoryName: string }>();
    const [filter, setFilter] = useState<any>({});
    const [cards, setCards] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [userId, setUserId] = useState('');

    const fetchCollection = async (newFilter: any, idUser: string, page: number) => {
        const pageable = {
            pageNumber: page - 1,
            pageSize: 15,
            sort: ['asc'],
        };

        const request: PubblicCollectionRequest = {
            idUser,
            pageable
        };

        setIsLoading(true);

        try {
            const apiResponse = await api.pubblicCollectionRaw(request);
            const jsonResponse = await apiResponse.raw.json();

            if (jsonResponse && jsonResponse.content) {
                setCards(jsonResponse.content);
                setTotalPages(jsonResponse.totalPages);
            }
        } catch (error) {
            console.error('Error fetching collection:', error);
            setError('Errore durante il recupero delle carte');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        if (user && user.id) {
            setUserId(user.id);
            fetchCollection(filter, user.id, currentPage);
        }
    }, [currentPage, filter]);

    const handleFilter = (newFilter: any) => {
        setFilter(newFilter);
        setCurrentPage(1);
        fetchCollection(newFilter, userId, 1);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    };

    return (
        <div style={{ padding: '20px', marginTop: "20px" }}>
            <StyledCard variant="outlined">
                <CardContent style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                        variant="h4"
                        style={{
                            fontWeight: 'bold',
                            fontSize: '2em',
                            fontFamily: '"Montserrat", sans-serif',
                            marginRight: "20px",
                            padding: '10px 0',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <People style={{ marginRight: '30px', fontSize: '1.7em' }} />
                        COLLEZIONI PUBBLICHE
                    </Typography>
                </CardContent>
            </StyledCard>

            <div className="cards-page">
                <div className="content">
                    <div className="filters">
                        <h2>Filtri Di Ricerca</h2>
                        {error && <p className="error">{error}</p>}
                        <FilterAndSort onFilter={handleFilter} isCollection={true} />
                    </div>
                    <div className="card-list-container">
                        {isLoading ? (
                            <div className="loading-spinner">
                                <CircularProgress />
                            </div>
                        ) : (
                            <>
                                <ListaCollezioniPubbliche filter={filter} collections={cards} />
                                {totalPages > 1 && (
                                    <ThemeProvider theme={theme}>
                                        <Stack spacing={2} alignItems="center" sx={{ marginTop: '20px' }}>
                                            <Pagination
                                                count={totalPages}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                                variant="outlined"
                                                shape="rounded"
                                                renderItem={(item) => <PaginationItem {...item} />}
                                            />
                                        </Stack>
                                    </ThemeProvider>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListCollectionsPublic;
