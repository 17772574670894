/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UtenteCriteria } from './UtenteCriteria';
import {
    UtenteCriteriaFromJSON,
    UtenteCriteriaFromJSONTyped,
    UtenteCriteriaToJSON,
} from './UtenteCriteria';
import type { TipoCardFilter } from './TipoCardFilter';
import {
    TipoCardFilterFromJSON,
    TipoCardFilterFromJSONTyped,
    TipoCardFilterToJSON,
} from './TipoCardFilter';
import type { UUIDFilter } from './UUIDFilter';
import {
    UUIDFilterFromJSON,
    UUIDFilterFromJSONTyped,
    UUIDFilterToJSON,
} from './UUIDFilter';
import type { DoubleFilter } from './DoubleFilter';
import {
    DoubleFilterFromJSON,
    DoubleFilterFromJSONTyped,
    DoubleFilterToJSON,
} from './DoubleFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';

/**
 * 
 * @export
 * @interface CollezioneCriteria
 */
export interface CollezioneCriteria {
    /**
     * 
     * @type {UUIDFilter}
     * @memberof CollezioneCriteria
     */
    id?: UUIDFilter;
    /**
     * 
     * @type {UtenteCriteria}
     * @memberof CollezioneCriteria
     */
    utente?: UtenteCriteria;
    /**
     * 
     * @type {StringFilter}
     * @memberof CollezioneCriteria
     */
    nome?: StringFilter;
    /**
     * 
     * @type {TipoCardFilter}
     * @memberof CollezioneCriteria
     */
    tipoCard?: TipoCardFilter;
    /**
     * 
     * @type {DoubleFilter}
     * @memberof CollezioneCriteria
     */
    mediaValutazioni?: DoubleFilter;
}

/**
 * Check if a given object implements the CollezioneCriteria interface.
 */
export function instanceOfCollezioneCriteria(value: object): value is CollezioneCriteria {
    return true;
}

export function CollezioneCriteriaFromJSON(json: any): CollezioneCriteria {
    return CollezioneCriteriaFromJSONTyped(json, false);
}

export function CollezioneCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollezioneCriteria {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : UUIDFilterFromJSON(json['id']),
        'utente': json['utente'] == null ? undefined : UtenteCriteriaFromJSON(json['utente']),
        'nome': json['nome'] == null ? undefined : StringFilterFromJSON(json['nome']),
        'tipoCard': json['tipoCard'] == null ? undefined : TipoCardFilterFromJSON(json['tipoCard']),
        'mediaValutazioni': json['mediaValutazioni'] == null ? undefined : DoubleFilterFromJSON(json['mediaValutazioni']),
    };
}

export function CollezioneCriteriaToJSON(value?: CollezioneCriteria | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': UUIDFilterToJSON(value['id']),
        'utente': UtenteCriteriaToJSON(value['utente']),
        'nome': StringFilterToJSON(value['nome']),
        'tipoCard': TipoCardFilterToJSON(value['tipoCard']),
        'mediaValutazioni': DoubleFilterToJSON(value['mediaValutazioni']),
    };
}

