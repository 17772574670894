import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, Button, createTheme, ThemeProvider, Card, CardContent } from '@mui/material';
import {CarteRestApi, ResourceOPDTO, RarityOpDTO, ColorOpDTO, FindAllEspansioneRequest} from '../../../api';
import {useLocation} from "react-router-dom";

const api = new CarteRestApi();

interface FilterAndSortProps {
    onFilter: (filter: any) => void;
    isCollection: boolean;
    tipoCard?: any;
}

const FilterAndSort: React.FC<FilterAndSortProps> = ({ onFilter, isCollection, tipoCard }) => {
    const location = useLocation();
    const [name, setName] = useState('');
    const [expansions, setExpansions] = useState<ResourceOPDTO[]>([]);
    const [rarity, setRarity] = useState<RarityOpDTO[]>([]);
    const [color, setColor] = useState<ColorOpDTO[]>([]);
    const [selectedExpansion, setSelectedExpansion] = useState('');
    const [selectedRarity, setSelectedRarity] = useState('');
    const [selectedColor, setSelectedColor] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const request: FindAllEspansioneRequest = {
                    cardType: tipoCard
                };
                const [expResponse, rarResponse, colResponse] = await Promise.all([
                    api.findAllEspansioneRaw(request),
                    api.findAllRarityRaw(),
                    api.findAllColorRaw()
                ]);

                const [expJson, rarJson, colJson] = await Promise.all([
                    expResponse.raw.json(),
                    rarResponse.raw.json(),
                    colResponse.raw.json()
                ]);

                const filteredExpansions = Array.isArray(expJson)
                    ? expJson.filter(exp => exp.nome && exp.nome.trim() !== '')
                    : expJson.content?.filter((exp: { nome: string; }) => exp.nome && exp.nome.trim() !== '') || [];

                setExpansions(filteredExpansions);
                setRarity(Array.isArray(rarJson) ? rarJson : rarJson.content || []);
                setColor(Array.isArray(colJson) ? colJson : colJson.content || []);
            } catch (error) {
                console.error('Errore durante il recupero dei dati:', error);
            }
        };

        fetchData().then(r => {});
    }, [tipoCard, location.pathname]);



    const handleFilter = async () => {
        if (tipoCard !== selectedExpansion) {
            setSelectedExpansion('');
            setSelectedRarity('');
            setSelectedColor('');
        }

        const criteria = {
            name: { contains: name },
            sourceName: selectedExpansion ? { equals: selectedExpansion } : undefined,
            rarity: selectedRarity ? { equals: selectedRarity } : undefined,
            colore: selectedColor ? { equals: selectedColor } : undefined,
        };



        try {
            onFilter({ criteria: criteria });
        } catch (error) {
            console.error('Errore durante il filtraggio:', error);
        }
    };

    const theme = createTheme({
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        marginBottom: '16px',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#3f51b5',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#303f9f',
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        marginTop: '16px',
                        borderRadius: '8px',
                    },
                    containedWarning: {
                        backgroundColor: '#ff9800',
                        '&:hover': {
                            backgroundColor: '#e68900',
                        },
                    },
                    containedError: {
                        backgroundColor: '#f44336',
                        '&:hover': {
                            backgroundColor: '#d32f2f',
                        },
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Card sx={{ padding: '16px', marginRight: '20px' }}>
                <CardContent>
                    <div>
                        <TextField
                            label="Cerca"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            select
                            label="Espansione"
                            value={selectedExpansion}
                            onChange={(e) => setSelectedExpansion(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            maxHeight: 224,
                                            width: 250,
                                        },
                                    },
                                },
                            }}
                        >
                            {expansions.map((exp) => (
                                <MenuItem key={exp.id} value={exp.nome}>
                                    {exp.nome}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            label="Rarità"
                            value={selectedRarity}
                            onChange={(e) => setSelectedRarity(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            maxHeight: 224,
                                            width: 250,
                                        },
                                    },
                                },
                            }}
                        >
                            {rarity.map((rar) => (
                                <MenuItem key={rar.id} value={rar.nome}>
                                    {rar.nome}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            label="Colore"
                            value={selectedColor}
                            onChange={(e) => setSelectedColor(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            maxHeight: 224,
                                            width: 250,
                                        },
                                    },
                                },
                            }}
                        >
                            {color.map((col) => (
                                <MenuItem key={col.id} value={col.nome}>
                                    {col.nome}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button onClick={handleFilter} variant="contained" color="warning" fullWidth>Filtra</Button>
                        <Button
                            onClick={() => {
                                setName('');
                                setSelectedExpansion('');
                                setSelectedRarity('');
                                setSelectedColor('');
                                onFilter({ criteria: {}, criteriaOnePiece: {} });
                            }}
                            variant="contained"
                            color="error"
                            fullWidth
                        >
                            Reset
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </ThemeProvider>
    );
};

export default FilterAndSort;
