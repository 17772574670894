import React from 'react';
import styles from './footer.module.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube, FaTelegramPlane } from 'react-icons/fa';
import logo from '../../assets/logo.png';

const Footer: React.FC = () => {

    const currentYear = new Date().getFullYear();
    const versione = '1.0.0'

    return (
        <footer className={styles.footerContainer}>
            <div className={styles.logoContainer}>
                <img src={logo} alt="Logo" className={styles.logo}/>
            </div>
            <div className={styles.footerTop}>
                <div className={styles.footerColumn}>
                    <h4 className={styles.nomicolonne}>AIUTO</h4>
                    <ul>
                        <li><a href="#!" className={styles.nomivoci}>Termini di utilizzo</a></li>
                        <li><a href="#!" className={styles.nomivoci}>Privacy Policy</a></li>
                        <li><a href="#!" className={styles.nomivoci}>Copyright</a></li>
                        <li><a href="#!" className={styles.nomivoci}>FAQ</a></li>
                        <li><a href="#!" className={styles.nomivoci}>Contattaci</a></li>
                    </ul>
                </div>
                <div className={styles.footerColumn}>
                    <h4 className={styles.nomicolonne}>FUNZIONALITÀ</h4>
                    <ul>
                        <li><a href="#!" className={styles.nomivoci}>La Mia Collezione</a></li>
                        <li><a href="#!" className={styles.nomivoci}>Autentica le tue carte</a></li>
                        <li><a href="#!" className={styles.nomivoci}>Esporta le tue collezioni</a></li>
                        <li><a href="#!" className={styles.nomivoci}>API <em>(Work In progress..)</em></a></li>
                    </ul>
                </div>
                <div className={styles.footerColumn}>
                    <h4 className={styles.nomicolonne}>GUIDE</h4>
                    <ul>
                        <li><a href="#!" className={styles.nomivoci}>Utilizzo del Portale</a></li>
                        <li><a href="#!" className={styles.nomivoci}>Deck Building</a></li>
                        <li><a href="#!" className={styles.nomivoci}>Riconoscere Differenze di Stampa</a></li>
                        <li><a href="#!" className={styles.nomivoci}>Video tutorial</a></li>
                    </ul>
                </div>
                <div className={styles.footerColumn}>
                    <h4 className={styles.nomicolonne}>TCG</h4>
                    <ul>
                        <li><a href="#!" className={styles.nomivoci}>One Piece</a></li>
                        <li><a href="#!" className={styles.nomivoci}>Magic: the Gathering</a></li>
                        <li><a href="#!" className={styles.nomivoci}>Pokémon</a></li>
                        <li><a href="#!" className={styles.nomivoci}>Yu-Gi-Oh!</a></li>
                        <li><a href="#!" className={styles.nomivoci}>Digimon</a></li>
                        <li><a href="#!" className={styles.nomivoci}>Dragon Ball Super</a></li>
                        <li><a href="#!" className={styles.nomivoci}>Disney Lorcana</a></li>
                    </ul>
                </div>
            </div>
            <div className={styles.socialIcons}>
                <a href="#!"><FaFacebookF/></a>
                <a href="#!"><FaTwitter/></a>
                <a href="#!"><FaInstagram/></a>
                <a href="#!"><FaTelegramPlane/></a>
                <a href="#!"><FaYoutube/></a>
            </div>
            <div className={styles.footerBottom}>
                <span>© Collectoon Ent. {currentYear} - Versione: {versione} -</span>
            </div>
            <span className={styles.subtitles}>
                    Collectoon ® is not produced by, endorsed by, supported by, or affiliated with The Pokémon Company (Pokémon), Nintendo, Game Freak, Creatures, Disney, Bandai, Konami, and/or Wizards of the Coast.</span>
        </footer>
    );
};

export default Footer;
