/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DragonballFusionWorldCriteria } from './DragonballFusionWorldCriteria';
import {
    DragonballFusionWorldCriteriaFromJSON,
    DragonballFusionWorldCriteriaFromJSONTyped,
    DragonballFusionWorldCriteriaToJSON,
} from './DragonballFusionWorldCriteria';
import type { CartaOPCriteria } from './CartaOPCriteria';
import {
    CartaOPCriteriaFromJSON,
    CartaOPCriteriaFromJSONTyped,
    CartaOPCriteriaToJSON,
} from './CartaOPCriteria';
import type { CollezioneCriteria } from './CollezioneCriteria';
import {
    CollezioneCriteriaFromJSON,
    CollezioneCriteriaFromJSONTyped,
    CollezioneCriteriaToJSON,
} from './CollezioneCriteria';
import type { CartaPokemonCriteria } from './CartaPokemonCriteria';
import {
    CartaPokemonCriteriaFromJSON,
    CartaPokemonCriteriaFromJSONTyped,
    CartaPokemonCriteriaToJSON,
} from './CartaPokemonCriteria';
import type { CartaCriteria } from './CartaCriteria';
import {
    CartaCriteriaFromJSON,
    CartaCriteriaFromJSONTyped,
    CartaCriteriaToJSON,
} from './CartaCriteria';
import type { LorcanaCriteria } from './LorcanaCriteria';
import {
    LorcanaCriteriaFromJSON,
    LorcanaCriteriaFromJSONTyped,
    LorcanaCriteriaToJSON,
} from './LorcanaCriteria';
import type { PageRequestDTO } from './PageRequestDTO';
import {
    PageRequestDTOFromJSON,
    PageRequestDTOFromJSONTyped,
    PageRequestDTOToJSON,
} from './PageRequestDTO';

/**
 * 
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * 
     * @type {CartaCriteria}
     * @memberof SearchRequest
     */
    criteria?: CartaCriteria;
    /**
     * 
     * @type {CartaOPCriteria}
     * @memberof SearchRequest
     */
    criteriaOnePiece?: CartaOPCriteria;
    /**
     * 
     * @type {CartaPokemonCriteria}
     * @memberof SearchRequest
     */
    criteriaPokemon?: CartaPokemonCriteria;
    /**
     * 
     * @type {DragonballFusionWorldCriteria}
     * @memberof SearchRequest
     */
    criteriaDragonballFs?: DragonballFusionWorldCriteria;
    /**
     * 
     * @type {LorcanaCriteria}
     * @memberof SearchRequest
     */
    criteriaLorcana?: LorcanaCriteria;
    /**
     * 
     * @type {CollezioneCriteria}
     * @memberof SearchRequest
     */
    collezioneCriteria?: CollezioneCriteria;
    /**
     * 
     * @type {PageRequestDTO}
     * @memberof SearchRequest
     */
    pageable?: PageRequestDTO;
}

/**
 * Check if a given object implements the SearchRequest interface.
 */
export function instanceOfSearchRequest(value: object): value is SearchRequest {
    return true;
}

export function SearchRequestFromJSON(json: any): SearchRequest {
    return SearchRequestFromJSONTyped(json, false);
}

export function SearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'criteria': json['criteria'] == null ? undefined : CartaCriteriaFromJSON(json['criteria']),
        'criteriaOnePiece': json['criteriaOnePiece'] == null ? undefined : CartaOPCriteriaFromJSON(json['criteriaOnePiece']),
        'criteriaPokemon': json['criteriaPokemon'] == null ? undefined : CartaPokemonCriteriaFromJSON(json['criteriaPokemon']),
        'criteriaDragonballFs': json['criteriaDragonballFs'] == null ? undefined : DragonballFusionWorldCriteriaFromJSON(json['criteriaDragonballFs']),
        'criteriaLorcana': json['criteriaLorcana'] == null ? undefined : LorcanaCriteriaFromJSON(json['criteriaLorcana']),
        'collezioneCriteria': json['collezioneCriteria'] == null ? undefined : CollezioneCriteriaFromJSON(json['collezioneCriteria']),
        'pageable': json['pageable'] == null ? undefined : PageRequestDTOFromJSON(json['pageable']),
    };
}

export function SearchRequestToJSON(value?: SearchRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'criteria': CartaCriteriaToJSON(value['criteria']),
        'criteriaOnePiece': CartaOPCriteriaToJSON(value['criteriaOnePiece']),
        'criteriaPokemon': CartaPokemonCriteriaToJSON(value['criteriaPokemon']),
        'criteriaDragonballFs': DragonballFusionWorldCriteriaToJSON(value['criteriaDragonballFs']),
        'criteriaLorcana': LorcanaCriteriaToJSON(value['criteriaLorcana']),
        'collezioneCriteria': CollezioneCriteriaToJSON(value['collezioneCriteria']),
        'pageable': PageRequestDTOToJSON(value['pageable']),
    };
}

