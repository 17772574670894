import React, {useState, useEffect, useRef} from 'react';
import { Dialog, DialogContent, IconButton, Grid, Chip, Tooltip, Button } from '@mui/material';
import { FaRedo, FaStar, FaTimes } from 'react-icons/fa';
import styles from './DettagliCarta.module.css';
import { getBlockerText } from "../../../utility/utils";

interface DettagliCartaProps {
    open: boolean;
    onClose: () => void;
    carta: any;
    tipo: 'one-piece' | 'Pokemon' | 'dragonball' | 'lorcana';
}
interface Field {
    label: string;
    key: string;
    optional: boolean;
}

const DettagliCarta: React.FC<DettagliCartaProps> = ({ open, onClose, carta, tipo }) => {
    const dialogContentRef = useRef<HTMLDivElement | null>(null);
    const [flipped, setFlipped] = useState(false);
    const [rotating, setRotating] = useState(false);
    const commonFields: Field[] = [
        { label: 'Rarità', key: 'rarity', optional: true },
        { label: 'Colore', key: 'colore', optional: true },
        { label: 'Costo', key: 'costo', optional: true },
    ];

    const specificFields: Record<string, Field[]> = {
        'one-piece': [
            { label: 'Attributo', key: 'attacco', optional: true },
            { label: 'Espansione', key: 'sourceName', optional: false },
            { label: 'Archetipo', key: 'appartenenza', optional: false },
            { label: 'Potenza', key: 'potenza', optional: false },
        ],
        Pokemon: [
            { label: 'Nome Espansione', key: 'espansione', optional: false },
            { label: 'Tipo', key: 'tipo', optional: false },
            { label: 'Punti', key: 'punti', optional: false },
            { label: 'Abilità', key: 'abilita', optional: true },
        ],
        dragonball: [
            { label: 'Espansione', key: 'nomeEspansione', optional: false },
            { label: 'Caratteristiche', key: 'features', optional: true },
            { label: 'Potenza', key: 'potenza', optional: true },
            { label: 'Potenza Combo', key: 'comboPower', optional: true },
        ],
        lorcana: [
            { label: 'Espansione', key: 'nomeEspansione', optional: false },
            { label: 'Caratteristiche', key: 'caratteristiche', optional: true },
            { label: 'Potenza', key: 'strength', optional: true },
            { label: 'Difesa', key: 'potenza', optional: true },
            { label: 'Franchise', key: 'franchiseName', optional: true },
            { label: 'Artista', key: 'artist', optional: true },
            { label: 'Aforisma', key: 'flavour', optional: true },
        ],
    };
    const fields = [...commonFields, ...(specificFields[tipo] || [])];

    const formatCardNumber = (input: string) => {
        return input ? input.replace(/_[^ ]*/g, '') : '';
    };

    const handleFlip = () => {
        setRotating(true);
        setTimeout(() => {
            setFlipped(prev => !prev);
            setRotating(false);
        }, 600);
    };

    useEffect(() => {
        if (!open) {
            setFlipped(false);
            setRotating(false);
        }
    }, [open]);

    useEffect(() => {
        if (dialogContentRef.current) {
            const dialogContent = dialogContentRef.current;
            if (dialogContent.scrollHeight > dialogContent.clientHeight) {
                dialogContent.style.overflowY = 'auto'; // Mostra overflow se necessario
            } else {
                dialogContent.style.overflowY = 'hidden'; // Nascondi se non necessario
            }
        }
    }, [carta]);


    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
            fullWidth
            PaperProps={{ classes: { root: styles.dialogPaper } }}
            BackdropProps={{ classes: { root: styles.dialogBackdrop } }}
        >
            <div className={styles.header}>
                <h2 className={styles.cardName}>
                    {carta.name}
                    <em>({tipo === 'lorcana' || tipo === 'dragonball' ? formatCardNumber(carta.idCard) : formatCardNumber(carta.numeroEspansione)})</em>
                </h2>
                <IconButton className={styles.closeButton} onClick={onClose}>
                    <FaTimes />
                </IconButton>
            </div>
            <DialogContent className={styles.dialogContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} className={styles.imageContainer}>
                        <img
                            src={flipped && carta.imageBack ? carta.imageBack : carta.imageUrl}
                            alt={carta.name}
                            className={`${styles.dialogImage} ${flipped ? styles.flipped : ''} ${rotating ? styles.rotating : ''}`}
                        />
                        {carta.imageBack && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleFlip}
                                className={`${styles.flipButton} ${flipped ? styles.flippedButton : ''}`}
                            >
                                <FaRedo style={{ marginRight: '8px' }} />
                                {flipped ? 'Fronte' : 'Awakened'}
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6} className={`${styles.detailsContainer} ${styles.centeredTable}`}>
                        <table className={styles.detailsTable}>
                            <tbody>
                            {fields.reduce<Field[][]>((rows, field, index) => {
                                if (index % 2 === 0) {
                                    rows.push([]);
                                }
                                rows[rows.length - 1].push(field);
                                return rows;
                            }, []).map((rowFields, rowIndex) => (
                                <tr key={rowIndex}>
                                    {rowFields.map((field) => (
                                        <React.Fragment key={field.key}>
                                            <th>{field.label.toLowerCase()}</th>
                                            <td className={`${styles.uppercase} ${field.key === 'flavour' ? styles.italic : ''}`}>
                                                {field.key === 'rarity' ? (
                                                    <>
                                                        {carta?.[field.key] || 'N/A'}
                                                        {carta?.alternativeArt && carta.alternativeArt.trim() !== '' && (
                                                            <Tooltip title="Versione Alternativa">
                                                                <FaStar className={styles.alternativeArtIcon}/>
                                                            </Tooltip>
                                                        )}
                                                    </>
                                                ) : field.key === 'caratteristiche' ? (
                                                    carta[field.key] ? (
                                                        (JSON.parse(carta[field.key]) as string[]).map((caratteristica: string, index: number) => (
                                                            <Chip key={index} label={caratteristica.toLowerCase()}
                                                                  className={styles.chip}/>
                                                        ))
                                                    ) : null
                                                ) : field.key === 'flavour' ? (
                                                    <span
                                                        className={styles.aforisma}
                                                        dangerouslySetInnerHTML={{
                                                            __html: carta[field.key] || (field.optional ? '-' : ''),
                                                        }}
                                                    />
                                                ) : (
                                                    (carta[field.key] || (field.optional ? '-' : '')).toLowerCase()
                                                )}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                    {rowFields.length === 1 && <td colSpan={2}></td>}
                                </tr>
                            ))}
                            <tr>
                                <th>Effetto</th>
                                <td colSpan={3} dangerouslySetInnerHTML={{__html: getBlockerText(carta.effetto)}}/>
                            </tr>
                            </tbody>
                        </table>

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );

};

export default DettagliCarta;
