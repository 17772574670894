// src/store/cardSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CarteRestApi, SearchRequest, TipoCardFilterEqualsEnum } from "../../api";

const api = new CarteRestApi();

const categoryMap: { [key: string]: TipoCardFilterEqualsEnum } = {
    'magic': TipoCardFilterEqualsEnum.MagicTheGathering,
    'pokemon': TipoCardFilterEqualsEnum.Pokemon,
    'yugioh': TipoCardFilterEqualsEnum.YugiOh,
    'one-piece': TipoCardFilterEqualsEnum.OnePiece,
    'dragonball': TipoCardFilterEqualsEnum.Dragonball,
    'lorcana': TipoCardFilterEqualsEnum.Lorcana,
};

interface CardState {
    cards: SearchRequest[];
    totalPages: number;
    currentPage: number;
    error: string | null;
    isLoading: boolean;
}

const initialState: CardState = {
    cards: [],
    totalPages: 0,
    currentPage: 1,
    error: null,
    isLoading: false,
};

interface FetchCardsParams {
    categoryName: string | undefined;
    newFilter: {
        criteria: any;
        criteriaOp?: any;
        criteriaPokemon?: any;
        criteriaDragonballFs?: any;
        criteriaLorcana?: any;
    };
    page: number;
}

export const fetchCards = createAsyncThunk(
    'cards/fetchCards',
    async ({ categoryName, newFilter, page }: FetchCardsParams) => {
        if (!categoryName) {
            throw new Error("categoryName deve essere definito");
        }

        const mappedCategoryName = categoryMap[categoryName.toLowerCase()];

        if (!mappedCategoryName) {
            throw new Error(`Categoria non riconosciuta: ${categoryName}`);
        }

        console.log('Mapped Category Name:', mappedCategoryName); // Aggiungi questo log per capire se la categoria è corretta

        const criteria = {
            tipoCard: { equals: mappedCategoryName },
            ...newFilter.criteria,
        };

        console.log('Criteria:', criteria); // Aggiungi un log per controllare la struttura di criteria

        const pageable = {
            pageNumber: page - 1,
            pageSize: 15,
        };

        let criteriaOnePiece = {};
        let criteriaPokemon = {};
        let criteriaDragonballFs = {};
        let criteriaLorcana = {};

        // Aggiungi dei log per capire come si popolano i vari criteria
        if (categoryName.toLowerCase() === 'one-piece') {
            criteriaOnePiece = {
                ...criteria,
                ...newFilter.criteriaOp,
            };
            console.log('Criteria OnePiece:', criteriaOnePiece);
        } else if (categoryName.toLowerCase() === 'pokemon') {
            criteriaPokemon = {
                ...criteria,
                ...newFilter.criteriaPokemon,
            };
            console.log('Criteria Pokemon:', criteriaPokemon);
        }
        else if (categoryName.toLowerCase() === 'dragonball') {
            criteriaDragonballFs = {
                ...criteria,
                ...newFilter.criteriaDragonballFs,
            };
            console.log('Criteria DragonballFs:', criteriaDragonballFs);
        }
        else if (categoryName.toLowerCase() === 'lorcana') {
            criteriaLorcana = {
                ...criteria,
                ...newFilter.criteriaLorcana,
            };
            console.log('Criteria Lorcana:', criteriaLorcana);
        }

        const searchRequest = {
            criteria,
            criteriaOnePiece: Object.keys(criteriaOnePiece).length ? criteriaOnePiece : undefined,
            criteriaPokemon: Object.keys(criteriaPokemon).length ? criteriaPokemon : undefined,
            criteriaDragonballFs: Object.keys(criteriaDragonballFs).length ? criteriaDragonballFs : undefined,
            criteriaLorcana: Object.keys(criteriaLorcana).length ? criteriaLorcana : undefined,
            pageable,
        };

        console.log('Search Request:', searchRequest); // Verifica la struttura finale della richiesta

        const response = await api.findByCriteriaSpecificCardRaw({ searchRequest });
        const jsonResponse = await response.raw.json();

        console.log('Risposta dal server:', jsonResponse); // Verifica cosa ti arriva dal server

        return jsonResponse;
    }
);



const CarteSlice = createSlice({
    name: 'cards',
    initialState,
    reducers: {
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCards.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchCards.fulfilled, (state, action) => {
                state.cards = action.payload.content;
                state.totalPages = action.payload.totalPages;
                state.isLoading = false;
            })
            .addCase(fetchCards.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Errore durante il recupero delle carte';
            });
    },
});

export const { setCurrentPage } = CarteSlice.actions;

export default CarteSlice.reducer;
