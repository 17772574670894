import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";
import { FaTimes } from "react-icons/fa";
import styled from "@emotion/styled";
import { CollezioneRestApi } from "../../../api";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { handleDeleteCollection } from "../../../store/collezioni/CollezioniHandlers";


interface DeleteCollectionProps {
    open: boolean;
    onClose: () => void;
    collectionId: string;
    nome: string;
}

const CustomButtonYes = styled(Button)`
    border-radius: 100px;
    padding: 12px 20px;
    background-color: crimson;
    font-family: "Montserrat", sans-serif !important;
    font-weight: bold;
    font-size: 0.7rem;
    color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: #d32f2f;
        transform: translateY(-2px);
    }
`;

const CustomButtonNo = styled(Button)`
    border-radius: 100px;
    padding: 12px 20px;
    background-color: #0066cc;
    font-family: "Montserrat", sans-serif !important;
    font-weight: bold;
    font-size: 0.7rem; 
    color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: #0056a3;
        transform: translateY(-2px);
    }
`;

const StyledDialog = styled(Dialog)`
    & .MuiPaper-root {
        border-radius: 40px !important;
        padding: 20px; 
        background-color: #f9f9f9;
    }
`;

const DeleteCollection: React.FC<DeleteCollectionProps> = ({
                                                               open,
                                                               onClose,
                                                               collectionId,
                                                               nome,
                                                           }) => {
    const dispatch = useDispatch<AppDispatch>();

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <div style={{ position: "relative", textAlign: "center" }}>
                    <Typography
                        variant="h4"
                        style={{
                            fontWeight: "bold",
                            fontSize: "32px",
                            fontFamily: '"Montserrat", sans-serif',
                        }}
                    >
                        Conferma Eliminazione
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        style={{
                            position: "absolute",
                            right: "16px",
                            top: "50%",
                            transform: "translateY(-50%)",
                        }}
                    >
                        <FaTimes />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" style={{ fontSize: "1.5rem", marginTop: "25px",  fontFamily: '"Montserrat", sans-serif' }}>
                    Sei sicuro di voler eliminare la collezione "{nome}" ?
                </Typography>
            </DialogContent>
            <DialogActions>
                <CustomButtonYes
                    onClick={() => {
                        handleDeleteCollection(dispatch, collectionId)
                            .then(() => {
                                onClose();
                            })
                            .catch((error) => {
                                console.error("Errore:", error);
                            });
                    }}
                >
                    Si
                </CustomButtonYes>
                <CustomButtonNo onClick={onClose}>No</CustomButtonNo>
            </DialogActions>
        </StyledDialog>
    );
};

export default DeleteCollection;
