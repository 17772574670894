import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {FaTimes} from "react-icons/fa";
import styles from "././AddCardInCollection.module.css";
import {
    AddCardCollectionRequest,
    CollectionByIdUtenteRequest,
    type CollezioneDTO,
    CollezioneRestApi
} from "../../../api";
import styled from "@emotion/styled";
import {toast} from "react-toastify";

const api = new CollezioneRestApi();

interface CreateCollectionProps {
    open: boolean;
    onClose: () => void;
    tipoCard: string;
    cartaId: string;
    nomeCarta: string;
    userId: string;
}

const CustomButton = styled(Button)`
    border-radius: 50px;
    padding: 10px 20px;
    background-color: green;
    font-family: 'Nunito, sans-serif';
    font-weight: bold;
    color: white;
    &:hover {
        background-color: #0056a3;
    }
`;

const CustomTextField = styled(TextField)`
    margin-bottom: 16px !important;
    & .MuiOutlinedInput-root {
        border-radius: 28px !important;
    }
`;

const CustomSelect = styled(Select)`
    margin-bottom: 16px !important;
    & .MuiOutlinedInput-root {
        border-radius: 28px !important;
    }
`;

const AddCardInCollection: React.FC<CreateCollectionProps> = ({ open, onClose, tipoCard, cartaId, nomeCarta, userId }) => {
    const [collections, setCollections] = useState<CollezioneDTO[]>([]);
    const [qty, setQty] = useState<number>(1);

    const [collectionId, setCollectionId] = useState('');

    const loadUserCollections = async () => {

        const request: CollectionByIdUtenteRequest = {
            userId: userId
        }

        try {
            const response = await api.collectionByIdUtenteRaw(request);
            const data = await response.raw.json();
            const filteredCollections = data.filter((collection: CollezioneDTO) => collection.tipoCard === tipoCard);
            setCollections(filteredCollections);
        } catch (error) {
            toast.error('Errore nel caricamento delle collezioni!');
        }
    };

    useEffect(() => {
        if (open) {
            loadUserCollections().then(r => {});
        }
    }, [open]);

    const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        if (value > 0) {
            setQty(value);
        } else {
            setQty(1);
        }
    };


    const handleCreate = async () => {
        const cardInCollection: AddCardCollectionRequest = {
            collezioneId: collectionId,
            cartaId: cartaId,
            body: qty!,
        };


        try {
            await api.addCardCollectionRaw(cardInCollection);
            toast.success('Carta aggiunta con successo alla Collezione!!');
            onClose();
        } catch (error) {
            toast.error('Si è verificato un errore!');
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{ classes: { root: styles.dialogPaper } }}
            BackdropProps={{ classes: { root: styles.dialogBackdrop } }}
        >
            <div className={styles.header}>
                <h2 className={styles.cardName}>Aggiungi {nomeCarta} alla Collezione</h2>
                <IconButton className={styles.closeButton} onClick={onClose}>
                    <FaTimes />
                </IconButton>
            </div>
            <DialogContent className={styles.dialogContent}>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Seleziona Collezione</InputLabel>
                    <CustomSelect
                        value={collectionId}
                        onChange={(e) => setCollectionId(e.target.value as string)}
                        className={styles.select}
                    >
                        {collections.map((collection) => (
                            <MenuItem key={collection.id} value={collection.id}>
                                {collection.nome}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </FormControl>
                <CustomTextField
                    label="Quantità"
                    fullWidth
                    margin="normal"
                    type="number"
                    value={qty}
                    onChange={handleQtyChange}
                    inputProps={{ min: 1, step: 1 }}
                />
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
                <CustomButton
                    variant="contained"
                    onClick={handleCreate}
                >
                    Aggiungi Alla Collezione
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddCardInCollection;
