import React, {useEffect, useState} from 'react';
import {Avatar, Card, CardContent, Grid, Typography} from '@mui/material';
import {Person} from '@mui/icons-material';
import {UtenteDTO, UtenteRestApi} from "../../api";
import {useParams} from "react-router-dom";
import {fetchCollezioni} from "../../store/collezioni/CollezioniSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import TabellaMiaCollezione from "../../components/Collection/Tabella-mia-collezione";

const userApi = new UtenteRestApi();
const UserDetail: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {idUser} = useParams<{ idUser: string | undefined }>();
    const collections = useSelector((state: RootState) => state.collezioni.collezioni);
    const [utente, setUtente] = useState<UtenteDTO | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetcUser = async () => {
            setLoading(true);
            setError(null);
            try {
                if (idUser !== undefined) {
                    const request = {
                        idUser: idUser,
                    };

                    const response = await userApi.getUserByIdRaw(request);
                    const jsonResponse = await response.raw.json();

                    setUtente(jsonResponse);

                }
            } catch (error) {
                setError('Failed to fetch collections. Please try again later.');
                console.error('Error fetching collection:', error);
            } finally {
                setLoading(false);
            }
        };

        fetcUser().then(r => {
        });
    }, [idUser]);


    const formatDate = (dataCreazione: any) => {
        if (!dataCreazione) return 'Data non disponibile';

        const [year, month, day, hour, minute, second, millisecond] = dataCreazione;

        const date = new Date(year, month - 1, day, hour, minute, second, millisecond);

        return date.toLocaleDateString('it-IT', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }) + ' ' + date.toLocaleTimeString('it-IT', {
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    const formatDateNascita = (data: any) => {
        if (!data || !Array.isArray(data)) return 'Data non disponibile';

        const [year, month, day] = data;

        const date = new Date(year, month - 1, day);
        return date.toLocaleDateString('it-IT', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };


    useEffect(() => {
        dispatch(fetchCollezioni({ idUtente: idUser }))
            .unwrap()
            .then(() => setLoading(false))
            .catch((err) => {
                setError(err.message);
                setLoading(false);
            });
    }, [dispatch, idUser]);


    return (
        <div style={{ padding: '20px', display: 'flex', justifyContent: 'center' }}>
            <Card style={{ width: '70%',
                borderRadius: '40px',
                padding: '20px',
                marginTop: "30px",
                marginBottom: "40px",
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Avatar sx={{ width: 100, height: 100 }}>
                                <Person style={{ fontSize: 60 }} />
                            </Avatar>
                        </Grid>

                        <Grid item>
                            <Typography variant="h4" style={{ fontWeight: 'bold',
                                fontFamily: '"Montserrat", sans-serif', marginLeft: "50px" , textTransform: "uppercase"}}>
                                {utente?.nome} {utente?.cognome}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary" style={{ fontWeight: 'bold',
                                fontFamily: '"Nunito", sans-serif'}}>
                                ({utente?.username})
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: '20px' }}>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="body2" color="textSecondary" style={{
                                fontFamily: '"Montserrat", sans-serif'}}>
                                Registrato dal:
                            </Typography>
                            <Typography variant="body1" style={{
                                marginTop: '10px',
                                fontFamily: '"Montserrat", sans-serif',
                                fontSize: "15px" }}>
                                {formatDate(utente?.dataCreazione)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="body2" color="textSecondary" style={{
                                fontFamily: '"Montserrat", sans-serif'}}>
                                Punti Collectoon:
                            </Typography>
                            <Typography variant="body1" style={{
                                marginTop: '10px',
                                fontFamily: '"Montserrat", sans-serif',
                                fontSize: "15px" }}>
                                {utente?.punteggioCliente}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="body2" color="textSecondary" style={{
                                fontFamily: '"Montserrat", sans-serif'}}>
                                Nato il:
                            </Typography>
                            <Typography variant="body1" style={{
                                marginTop: '10px',
                                fontFamily: '"Montserrat", sans-serif',
                                fontSize: "15px" }}>
                                {formatDateNascita(utente?.dataNascita)}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

export default UserDetail;
