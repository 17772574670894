import React from 'react';
import CardOnePieceComponent from '../OnePiece/SingolaCartaOnePiece/cardOnePieceComponent';
import styles from './CardList.module.css';
import CardPokemonComponent from "../Pokemon/SingolaCartaPokemon/cardPokemonComponent";
import {CardListProps} from "../../../utility/props";


const CardList: React.FC<CardListProps> = ({ filter, cards, tipoCard }) => {

    return (
        <div className={styles.cardList}>
            {cards.map((card, index) => (
                (tipoCard === 'one-piece' || tipoCard === 'dragonball' || tipoCard === 'lorcana') && (
                    <CardOnePieceComponent
                        key={index}
                        carta={card}
                        tipoCard={tipoCard}/>
                )
            ))}
            {cards.map((card, index) => (
                (tipoCard === 'pokemon') && (
                    <CardPokemonComponent
                        key={index}
                        name={card.name}
                        image={card.imageUrl}
                        rarita={card.rarity}
                        idCartaPokemon={card.idCartaPokemon}
                        supertype={card.supertype}
                        artist={card.artist}
                        evolvesFrom={card.evolvesFrom}
                        hp={card.hp}
                        series={card.series}
                        retreatCost={card.retreatCost}
                        types={card.types}
                        set={card.set}
                    />
                )
            ))}
        </div>
    );
};

export default CardList;
