import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Card,
    CardContent,
    Divider,
} from '@mui/material';
import { UtenteDTO } from "../../api";
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';

type UserProfileProps = {
    user: UtenteDTO;
};

const UserProfile: React.FC<UserProfileProps> = ({ user }) => {
    const [birthDate, setBirthDate] = useState<Dayjs | null>(
        user.dataNascita ? dayjs(user.dataNascita) : null
    );

    return (
        <Card sx={{
            boxShadow: 5,
            borderRadius: 3,
            padding: 3,
            backgroundColor: '#f9f9f9',
            '&:hover': {
                boxShadow: 10,
            }
        }}>
            <CardContent>
                <Typography variant="h4" gutterBottom sx={{
                    fontFamily: '"Montserrat", sans-serif',
                    fontWeight: 'bold',
                    fontSize: '2rem',
                    textAlign: 'center', color: '#3f51b5'
                }}>
                    PROFILO UTENTE
                </Typography>
                <Typography variant="body1" sx={{
                    fontFamily: 'Verdana, sans-serif',
                    fontSize: '0.9rem',
                    fontStyle: 'italic'
                }}>
                    Qui puoi vedere e modificare le tue informazioni personali.
                </Typography>
                <Divider sx={{ marginY: 2 }} />

                <Typography variant="h6" gutterBottom sx={{
                    fontFamily: '"Montserrat", sans-serif',
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    color: '#3f51b5',
                    marginBottom: '30px'
                }}>
                    Anagrafica Utente
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box component="form" noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Nome"
                                    defaultValue={user.nome}
                                    variant="outlined"
                                    required
                                    InputLabelProps={{ style: { fontFamily: '"Nunito", sans-serif' } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Cognome"
                                    defaultValue={user.cognome}
                                    variant="outlined"
                                    required
                                    InputLabelProps={{ style: { fontFamily: '"Nunito", sans-serif' } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Username"
                                    defaultValue={user.username}
                                    variant="outlined"
                                    required
                                    InputLabelProps={{ style: { fontFamily: '"Nunito", sans-serif' } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    defaultValue={user.email}
                                    variant="outlined"
                                    required
                                    InputLabelProps={{ style: { fontFamily: '"Nunito", sans-serif' } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DatePicker
                                    label="Data di Nascita"
                                    value={birthDate}
                                    onChange={(newValue: Dayjs | null) => setBirthDate(newValue)}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            variant: "outlined",
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </LocalizationProvider>

                <Divider sx={{ marginY: 4 }} />

                <Typography variant="h6" gutterBottom sx={{
                    fontFamily: '"Montserrat", sans-serif',
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    color: '#3f51b5',
                    marginBottom: '30px'
                }}>
                    Cambia Password
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Nuova Password"
                            type="password"
                            variant="outlined"
                            InputLabelProps={{ style: { fontFamily: '"Nunito", sans-serif' } }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Conferma Password"
                            type="password"
                            variant="outlined"
                            InputLabelProps={{ style: { fontFamily: '"Nunito", sans-serif' } }}
                        />
                    </Grid>
                </Grid>

                <Divider sx={{ marginY: 4 }} />

                <Typography variant="h6" gutterBottom sx={{
                    fontFamily: '"Montserrat", sans-serif',
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    color: '#3f51b5',
                    marginBottom: '30px'
                }}>
                    Profilo Collectoon
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Card sx={{ padding: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Collectoon Point
                            </Typography>
                            <Typography variant="h5" sx={{ color: '#3f51b5' }}>
                                {user?.punteggioCliente}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card sx={{ padding: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Collezioni Create
                            </Typography>
                            <Typography variant="h5" sx={{ color: '#3f51b5' }}>
                                5
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>

                <Box mt={4} display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="primary"
                            sx={{
                                padding: '10px 20px',
                                fontFamily: '"Montserrat", sans-serif',
                                marginBottom: '30px',
                                fontSize: '16px',
                                backgroundColor: '#3f51b5',
                                '&:hover': {
                                    backgroundColor: '#303f9f',
                                },
                            }}>
                        Salva Modifiche
                    </Button>
                </Box>

            </CardContent>
        </Card>
    );
};

export default UserProfile;
