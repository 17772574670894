import React, {useEffect, useState} from 'react';
import {GetCardWishlistByUtenteRequest, WishlistRestApi} from "../api";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import styles from "../styles/MyCollectionDetail.module.css";
import CardCollectionList from "../components/Card/Lista/Card-list-collection";

const wishlistApi = new WishlistRestApi();

const WishlistPage: React.FC = () => {
    const [carte, setCarte] = useState<any[]>([]);
    const [utente, setUtente] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);


    const fetchCarte = async () => {
        setIsLoading(true);

        const utente = localStorage.getItem('user');
        if (!utente) {
            setError('Utente non trovato nel localStorage');
            setIsLoading(false);
            return;
        }

        const datiUtente = JSON.parse(utente);
        setUtente(datiUtente);

        if (!datiUtente.id) {
            setError('ID utente non trovato');
            setIsLoading(false);
            return;
        }

        const requestData: GetCardWishlistByUtenteRequest = {
            idUser: datiUtente.id,
        };

        try {
            const response = await wishlistApi.getCardWishlistByUtenteRaw(requestData);
            const jsonResponse = await response.raw.json();

            if (jsonResponse && jsonResponse.length > 0) {
                setCarte(jsonResponse);
            } else {
                setError('Nessuna carta trovata per questo utente.');
            }
        } catch (error) {
            setError('Errore durante il recupero degli obiettivi');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCarte().then(r => {
        });
    }, []);

    const breadcrumbItems = [
        {name: 'Home', link: '/'},
        {name: `Wishlist`, link: '/wishlist-utente'},
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems}/>
            <div className={styles.profileContainer}>
                <div className={styles.profileHeader}>
                    <div className={styles.userInfo}>
                        <h1 className={styles.collectionName}>
                            WishList {utente?.username.toUpperCase()}
                        </h1>
                    </div>
                </div>
                {carte.length > 0 && (
                    <div className={styles.statsContainer}>

                        <div className={styles.stats}>
                            <span>{carte.length}</span>
                            <span>TOTALE CARTE DESIDERATE</span>
                        </div>

                    </div>
                )}

                <div className={styles.cardGridContainer}>
                    {carte.length > 0 ? (
                        <CardCollectionList
                            cardsWishlist={carte}
                            idCollection={undefined}
                            cardsPerPage={12}
                            showDeleteIcon={true}
                        />
                    ) : (
                        <div style={{
                            textAlign: 'center',
                            color: '#A9A9A9',
                            fontSize: '1.8em',
                            fontFamily: '"Montserrat", sans-serif',
                            marginTop: '20px'
                        }}>
                            Non ci sono elementi da visualizzare.
                        </div>
                    )}
                </div>
            </div>
        </>

    );
};

export default WishlistPage;
