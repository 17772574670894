import React, {useEffect, useState} from 'react';
import Tooltip from '@mui/material/Tooltip';
import {FaEye, FaHeart, FaPlus} from 'react-icons/fa';
import styles from './CardComponent.module.css';
import DettagliCartaOnePiece from '../../Dettagli-carta-onepiece/dettagli-carta-onePiece';
import {AddCardWishlistRequest, WishlistRestApi} from "../../../../api";
import {toast} from "react-toastify";
// import AddCardInCollection from '../../../Dialog/AddCardInCollection/add-card-in-collection';
import AddCardInCollection from "../../../dialog/AddCardInCollection/add-card-in-collection";

interface CardComponentProps {
    carta: any;
    tipoCard: any;
}

const api = new WishlistRestApi();

const CardOnePieceComponent: React.FC<CardComponentProps> = ({
                                                                 carta,
                                                                 tipoCard
                                                             }) => {
    const [open, setOpen] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [userId, setUserId] = useState('');

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        if (user && user.id) {
            setUserId(user.id);
        }
    }, []);

    const addToWishlist = async () => {
        if (!userId) {
            console.error("User not logged in");
            return;
        }

        const request: AddCardWishlistRequest = {
            wishlistUtenteInsertDTO: {
                cartaId: carta?.id,
                utenteId: userId,
            }
        };

        try {
            await api.addCardWishlistRaw(request);
            toast.success('Carta aggiunta alla Wishlist!');
        } catch (error) {
            toast.error("Errore");
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };

    const formatCardNumber = (input: string) => {
        return input ? input.replace(/_[^ ]*/g, '') : '';
    };


    return (
        <div className={styles.card}>
            {carta.prezzo !== null && carta.prezzo !== undefined && (
                <div className={styles.priceTag}>
                  € {typeof carta.prezzo === 'number' ? carta.prezzo : 'N/A'}
                </div>
            )}

            <div className={styles.cardImageContainer}>
                <img src={carta?.imageUrl} alt={carta?.name} className={styles.cardImage}/>
                <div className={styles.symbolsContainer}>
                    <Tooltip title="Visualizza">
                        <div className={`${styles.symbol} ${styles.viewCard}`} onClick={handleClickOpen}>
                            <FaEye/>
                        </div>
                    </Tooltip>

                    {userId && (
                        <>
                            <Tooltip title="Aggiungi Alla collezione">
                                <div className={`${styles.symbol} ${styles.addColl}`} onClick={handleClickOpenAdd}>
                                    <FaPlus/>
                                </div>
                            </Tooltip>
                            <Tooltip title="Aggiungi alla WishList">
                                <div className={`${styles.symbol} ${styles.heartSymbol}`} onClick={addToWishlist}>
                                    <FaHeart/>
                                </div>
                            </Tooltip>
                        </>
                    )}
                </div>
            </div>
            {/*<div className={styles.cardDetails}>*/}
            {/*    <h3 className={styles.cardTitle}>*/}
            {/*        {carta?.name}*/}
            {/*        {tipoCard === 'dragonball' ? ` - ${formatCardNumber(carta.idCard)} -` : ''}*/}
            {/*         (<em>{tipoCard === 'dragonball' ? carta.nomeEspansione : (tipoCard !== 'one_piece' ? carta.idCard : carta.numeroEspansione)}</em>)*/}
            {/*    </h3>*/}
            {/*</div>*/}
            <DettagliCartaOnePiece
                open={open}
                onClose={handleClose}
                carta={carta}
                tipo={tipoCard}/>
            <AddCardInCollection
                open={openAdd}
                onClose={handleCloseAdd}
                tipoCard={"ONE_PIECE"}
                cartaId={carta?.id}
                nomeCarta={carta?.name}
                userId={userId}
            />
        </div>
    );
};

export default CardOnePieceComponent;
