import React, { useEffect, useState } from "react";
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { CartaDTOTipoCardEnum, CollezioneRestApi } from "../../../api";
import styled from "@emotion/styled";
import { transformCardType } from "../../../utility/utils";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { handleUpdateCollection } from "../../../store/collezioni/CollezioniHandlers";
import { useFetchCollezione } from "../../../store/collezioni/Hooks/useFetchCollezione";

const api = new CollezioneRestApi();

interface EditCollectionProps {
    open: boolean;
    onClose: () => void;
    collectionId: string | undefined;
}

const CustomButton = styled(Button)`
    border-radius: 100px;
    padding: 12px 20px;
    background-color: chocolate;
    font-family: "Montserrat", sans-serif !Important;
    font-weight: bold;
    font-size: 0.7rem;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: #005cb2;
        transform: translateY(-2px); 
    }
`;

const CancelButton = styled(Button)`
    border-radius: 100px;
    padding: 12px 20px;
    background-color: crimson;
    font-family: "Montserrat", sans-serif !Important;
    font-weight: bold;
    font-size: 0.7rem;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: #d32f2f; 
        transform: translateY(-2px);
    }
`;

const CustomTextField = styled(TextField)`
    margin-top: 20px !important;  /* Aggiungi il margin-top */
    margin-bottom: 16px !important;
    font-family: "Montserrat", sans-serif !Important;

    & .MuiOutlinedInput-root {
        border-radius: 28px !important;
        font-size: 1.1rem;
        padding: 14px !important;
    }
`;


const CustomSelect = styled(Select)`
    margin-top: 20px !important;
    margin-bottom: 16px !important;
    font-family: "Montserrat", sans-serif !Important;

    & .MuiOutlinedInput-root {
        border-radius: 28px !important;
        font-size: 1.1rem;
        padding: 14px !important;
    }
`;

const StyledDialog = styled(Dialog)`
    & .MuiPaper-root {
        border-radius: 30px !important;
    }
`;
const CustomFormControlLabel = styled(FormControlLabel)` 
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold !important;
`;

const EditCollection: React.FC<EditCollectionProps> = ({ open, onClose, collectionId }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { collezione, loading, error } = useFetchCollezione(collectionId);
    const [nomeCollezione, setNomeCollezione] = useState("");
    const [tipoCollezione, setTipoCollezione] = useState<CartaDTOTipoCardEnum>();
    const [isPrivata, setIsPrivata] = useState(false);

    useEffect(() => {
        if (collezione) {
            setNomeCollezione(collezione.nome ?? "");
            setTipoCollezione(collezione.tipoCard ?? CartaDTOTipoCardEnum.MagicTheGathering);
            setIsPrivata(collezione.isPrivate ?? false);
        }
    }, [collezione]);

    return (
        <StyledDialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <div style={{ position: 'relative', textAlign: 'center', padding: '16px 0' }}>
                    <Typography
                        variant="h4"
                        style={{ fontWeight: 'bold',
                            fontSize: '32px',
                        fontFamily: '"Montserrat", sans-serif'}}
                    >
                        MODIFICA "{nomeCollezione}"
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        style={{
                            position: 'absolute',
                            right: '16px',
                            top: '50%',
                            transform: 'translateY(-50%)'
                        }}
                    >
                        <FaTimes />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent>
                {loading ? (
                    <div style={{textAlign: 'center', padding: '20px'}}>
                        <CircularProgress/>
                    </div>
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <>
                        <CustomTextField
                            label="Nome Collezione"
                            fullWidth
                            value={nomeCollezione}
                            onChange={(e) => setNomeCollezione(e.target.value)}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Tipo Collezione</InputLabel>
                            <CustomSelect
                                value={tipoCollezione}
                                onChange={(e) => setTipoCollezione(e.target.value as CartaDTOTipoCardEnum)}
                                disabled
                            >
                                {Object.keys(CartaDTOTipoCardEnum).map((key) => (
                                    <MenuItem key={key} value={CartaDTOTipoCardEnum[key as keyof typeof CartaDTOTipoCardEnum]}>
                                        {transformCardType(CartaDTOTipoCardEnum[key as keyof typeof CartaDTOTipoCardEnum])}
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isPrivata}
                                    onChange={(e) => setIsPrivata(e.target.checked)}
                                    sx={{
                                        '&.Mui-checked': {
                                            color: 'darkgreen',
                                        },
                                    }}
                                />

                            }
                            label="Privata"
                            sx={{
                                "& .MuiFormControlLabel-label": {
                                    fontFamily: "'Nunito', sans-serif !important",
                                    fontSize: '20px'
                                }
                            }}
                        />


                    </>
                )}
            </DialogContent>
            <DialogActions>
                <CustomButton
                    onClick={() => {
                        handleUpdateCollection(dispatch, collectionId, nomeCollezione, isPrivata)
                            .then(() => {
                                onClose();
                            })
                            .catch((error) => {
                                console.error("Errore:", error);
                            });
                    }}
                >
                    Modifica
                </CustomButton>
                <CancelButton onClick={onClose}>Annulla</CancelButton>
            </DialogActions>
        </StyledDialog>
    );
};

export default EditCollection;
