import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import styles from '../HorizontalMenu/HorizontalMenu.module.css';

const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: '20px',
    padding: '6px 12px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'none', // Per mantenere il testo normale come nell'immagine
    transition: 'background 0.3s, box-shadow 0.3s',
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    '&:hover': {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .5)',
    },
}));

const CustomButtonAlt = styled(Button)(({ theme }) => ({
    borderRadius: '20px',
    padding: '6px 12px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'none', // Per mantenere il testo normale come nell'immagine
    transition: 'background 0.3s, box-shadow 0.3s',
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
    '&:hover': {
        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .5)',
    },
}));

const CustomButtonMarket = styled(Button)(({ theme }) => ({
    borderRadius: '20px',
    padding: '6px 12px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'none', // Per mantenere il testo normale come nell'immagine
    transition: 'background 0.3s, box-shadow 0.3s',
    background: 'linear-gradient(45deg, #66bb6a 30%, #43a047 90%)',
    boxShadow: '0 3px 5px 2px rgba(102, 187, 106, .3)',
    '&:hover': {
        background: 'linear-gradient(45deg, #66bb6a 30%, #43a047 90%)',
        boxShadow: '0 3px 5px 2px rgba(102, 187, 106, .5)',
    },
}));

const ButtonGroup: React.FC<{ userId: string }> = ({ userId }) => (
    <div className={styles.buttonGroup}>
        <Link to={`/my-collection/${userId}`} style={{ textDecoration: 'none' }}>
            <CustomButton variant="contained">Le Mie Collezioni</CustomButton>
        </Link>
        <Link to={`/collections-user/public`} style={{ textDecoration: 'none' }}>
        <CustomButtonAlt variant="contained">Collezioni Pubbliche</CustomButtonAlt>
        </Link>
        <CustomButtonMarket variant="contained">Mercato</CustomButtonMarket>
    </div>
);

export default ButtonGroup;
