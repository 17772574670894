import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@mui/material';
import {
    ConfigurazioniRestApi,
    CreatedConfigRequest,
} from "../../api";
import { toast } from "react-toastify";
import {
    ConfigurazioniUtente,
    ConfigurazioniUtenteFrequenzaBackupEnum,
    ConfigurazioniUtenteLinguaPreferitaEnum,
    ConfigurazioniUtentePreferenzaCollezioneEnum, ConfigurazioniUtenteTipoFormatoBackupEnum,
    ConfigurazioniUtenteTipoInterfacciaEnum
} from '../../api/models/ConfigurazioniUtente';

interface ConfigurazioniProps {
    configurazioni: ConfigurazioniUtente | null;
    utente?: any;
}

const configurazioniApi = new ConfigurazioniRestApi();

const Configurazioni: React.FC<ConfigurazioniProps> = ({ configurazioni, utente }) => {
    const [collezioneAutomatica, setCollezioneAutomatica] = useState<boolean>(configurazioni?.collezioneAutomatica || false);
    const [nomeCollezioneAuto, setNomeCollezioneAuto] = useState<string>(configurazioni?.nomeCollezioneAutomatica || "");
    const [notificheEmail, setNotificheEmail] = useState<boolean>(configurazioni?.notificheEmail || false);
    const [tipoInterfaccia, setTipoInterfaccia] = useState<ConfigurazioniUtenteTipoInterfacciaEnum>(
        ConfigurazioniUtenteTipoInterfacciaEnum.Classic
    );
    const [preferenzeCollezione, setPreferenzeCollezione] = useState<ConfigurazioniUtentePreferenzaCollezioneEnum>(
        ConfigurazioniUtentePreferenzaCollezioneEnum.Griglia
    );
    const [tipoLingua, setTipoLingua] = useState<ConfigurazioniUtenteLinguaPreferitaEnum>(
        ConfigurazioniUtenteLinguaPreferitaEnum.Italiano
    );
    const [elementiPerPagina, setElementiPerPagina] = useState<number>(configurazioni?.elementiPerPagina || 12);
    const [notificaObiettiviCompletati, setNotificaObiettiviCompletati] = useState<boolean>(false);
    const [backupAutomatico, setBackupAutomatico] = useState<boolean>(false);
    const [frequenzaBackup, setFrequenzaBackup] = useState<ConfigurazioniUtenteFrequenzaBackupEnum>(
        ConfigurazioniUtenteFrequenzaBackupEnum.Settimanale
    );
    const [tipoFormato, setTipoFormato] = useState<ConfigurazioniUtenteTipoFormatoBackupEnum>(
        ConfigurazioniUtenteTipoFormatoBackupEnum.Pdf
    );

    useEffect(() => {
        if (configurazioni) {
            setCollezioneAutomatica(configurazioni.collezioneAutomatica || false);
            setNomeCollezioneAuto(configurazioni.nomeCollezioneAutomatica || "");
            setNotificheEmail(configurazioni.notificheEmail || false);
            setElementiPerPagina(configurazioni.elementiPerPagina || 12);
            setTipoInterfaccia(configurazioni.tipoInterfaccia || ConfigurazioniUtenteTipoInterfacciaEnum.Classic);
            setPreferenzeCollezione(configurazioni.preferenzaCollezione || ConfigurazioniUtentePreferenzaCollezioneEnum.Griglia);
            setTipoLingua(configurazioni.linguaPreferita || ConfigurazioniUtenteLinguaPreferitaEnum.Italiano);
            setBackupAutomatico(configurazioni.backupAutomatico || false);
            setFrequenzaBackup(configurazioni.frequenzaBackup || ConfigurazioniUtenteFrequenzaBackupEnum.Settimanale);
            setTipoFormato(configurazioni.tipoFormatoBackup || ConfigurazioniUtenteTipoFormatoBackupEnum.Pdf);
            setNotificaObiettiviCompletati(configurazioni.notificaObiettivoCompletato || false);
        }
    }, [configurazioni]);

    const handleSave = async () => {
        const formData = {
            id: configurazioni?.id,
            collezioneAutomatica,
            nomeCollezioneAutomatica: nomeCollezioneAuto,
            notificheEmail,
            tipoInterfaccia,
            preferenzeCollezione,
            linguaPreferita: tipoLingua,
            elementiPerPagina,
            notificaObiettivoCompletato: notificaObiettiviCompletati,
            backupAutomatico,
            frequenzaBackup,
            tipoFormatoBackup: tipoFormato,
            utenteId: utente.id,
        };

        const requestData: CreatedConfigRequest = { configurazioniUtenteDTO: formData };

        await configurazioniApi.createdConfigRaw(requestData);
        toast.success('Configurazioni aggiornate!');
    };

    const backgroundColor = { bgcolor: 'white', borderRadius: '4px' };

    return (
        <Container sx={{ padding: '2rem', backgroundColor: '#f5f5f5', borderRadius: '8px', boxShadow: 2 }}>
            {!configurazioni ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="medium"
                                    checked={collezioneAutomatica}
                                    onChange={() => setCollezioneAutomatica(!collezioneAutomatica)}
                                />
                            }
                            label="Collezione Automatica"
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontFamily: '"Montserrat", sans-serif',
                                    fontSize: '18px',
                                    color: '#000',
                                },
                            }}
                        />
                    </Grid>

                    {collezioneAutomatica && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Nome Collezione Auto"
                                value={nomeCollezioneAuto}
                                onChange={(e) => setNomeCollezioneAuto(e.target.value)}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                sx={{
                                    ...backgroundColor,
                                    '& .MuiInputLabel-root': {
                                        fontFamily: '"Montserrat", sans-serif',
                                        fontSize: '18px',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& input': {
                                            fontFamily: '"Montserrat", sans-serif',
                                            fontSize: '18px',
                                        },
                                        '& fieldset': {
                                            borderColor: 'gray',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'blue',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'blue',
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="medium"
                                    checked={notificheEmail}
                                    onChange={() => setNotificheEmail(!notificheEmail)}
                                />
                            }
                            label="Notifiche Email"
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontFamily: '"Montserrat", sans-serif',
                                    fontSize: '18px',
                                    color: '#000',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="medium"
                                    checked={notificaObiettiviCompletati}
                                    onChange={() => setNotificaObiettiviCompletati(!notificaObiettiviCompletati)}
                                />
                            }
                            label="Notifica Obiettivi Completati"
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontFamily: '"Montserrat", sans-serif',
                                    fontSize: '18px',
                                    color: '#000',
                                },
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel sx={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Tipo Interfaccia</InputLabel>
                            <Select
                                value={tipoInterfaccia}
                                onChange={(e) => setTipoInterfaccia(e.target.value as ConfigurazioniUtenteTipoInterfacciaEnum)}
                                label="Tipo Interfaccia"
                                sx={{
                                    ...backgroundColor,
                                    fontFamily: '"Montserrat", sans-serif',
                                    fontSize: '18px',
                                    '& .MuiSelect-select': {
                                        padding: '10px',
                                    },
                                    '& .MuiInputBase-input': {
                                        padding: '10px',
                                    },
                                }}
                            >
                                {Object.values(ConfigurazioniUtenteTipoInterfacciaEnum).map((tipo) => (
                                    <MenuItem key={tipo} value={tipo} sx={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }}>
                                        {tipo}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel sx={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Preferenza Collezione</InputLabel>
                            <Select
                                value={preferenzeCollezione}
                                onChange={(e) => setPreferenzeCollezione(e.target.value as ConfigurazioniUtentePreferenzaCollezioneEnum)}
                                label="Preferenza Collezione"
                                sx={{
                                    ...backgroundColor,
                                    fontFamily: '"Montserrat", sans-serif',
                                    fontSize: '18px',
                                    '& .MuiSelect-select': {
                                        padding: '10px',
                                    },
                                    '& .MuiInputBase-input': {
                                        padding: '10px',
                                    },
                                }}
                            >
                                {Object.values(ConfigurazioniUtentePreferenzaCollezioneEnum).map((preferenza) => (
                                    <MenuItem key={preferenza} value={preferenza} sx={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }}>
                                        {preferenza}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel sx={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Lingua Preferita</InputLabel>
                            <Select
                                value={tipoLingua}
                                onChange={(e) => setTipoLingua(e.target.value as ConfigurazioniUtenteLinguaPreferitaEnum)}
                                label="Lingua Preferita"
                                sx={{
                                    ...backgroundColor,
                                    fontFamily: '"Montserrat", sans-serif',
                                    fontSize: '18px',
                                    '& .MuiSelect-select': {
                                        padding: '10px',
                                    },
                                    '& .MuiInputBase-input': {
                                        padding: '10px',
                                    },
                                }}
                            >
                                {Object.values(ConfigurazioniUtenteLinguaPreferitaEnum).map((lingua) => (
                                    <MenuItem key={lingua} value={lingua} sx={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }}>
                                        {lingua}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Elementi per Pagina"
                            type="number"
                            value={elementiPerPagina}
                            onChange={(e) => setElementiPerPagina(Number(e.target.value))}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            sx={{
                                ...backgroundColor,
                                '& .MuiInputLabel-root': {
                                    fontFamily: '"Montserrat", sans-serif',
                                    fontSize: '18px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& input': {
                                        fontFamily: '"Montserrat", sans-serif',
                                        fontSize: '18px',
                                    },
                                    '& fieldset': {
                                        borderColor: 'gray',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'blue',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'blue',
                                    },
                                },
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="medium"
                                    checked={backupAutomatico}
                                    onChange={() => setBackupAutomatico(!backupAutomatico)}
                                />
                            }
                            label="Backup Automatico"
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontFamily: '"Montserrat", sans-serif',
                                    fontSize: '18px',
                                    color: '#000',
                                },
                            }}
                        />
                    </Grid>

                    {backupAutomatico && (
                        <>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel sx={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Frequenza di Backup</InputLabel>
                                    <Select
                                        value={frequenzaBackup}
                                        onChange={(e) => setFrequenzaBackup(e.target.value as ConfigurazioniUtenteFrequenzaBackupEnum)}
                                        label="Frequenza di Backup"
                                        sx={{
                                            ...backgroundColor,
                                            fontFamily: '"Montserrat", sans-serif',
                                            fontSize: '18px',
                                            '& .MuiSelect-select': {
                                                padding: '10px',
                                            },
                                            '& .MuiInputBase-input': {
                                                padding: '10px',
                                            },
                                        }}
                                    >
                                        {Object.values(ConfigurazioniUtenteFrequenzaBackupEnum).map((frequenza) => (
                                            <MenuItem key={frequenza} value={frequenza} sx={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }}>
                                                {frequenza}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel sx={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Tipo Formato Backup</InputLabel>
                                    <Select
                                        value={tipoFormato}
                                        onChange={(e) => setTipoFormato(e.target.value as ConfigurazioniUtenteTipoFormatoBackupEnum)}
                                        label="Tipo Formato Backup"
                                        sx={{
                                            ...backgroundColor,
                                            fontFamily: '"Montserrat", sans-serif',
                                            fontSize: '18px',
                                            '& .MuiSelect-select': {
                                                padding: '10px',
                                            },
                                            '& .MuiInputBase-input': {
                                                padding: '10px',
                                            },
                                        }}
                                    >
                                        {Object.values(ConfigurazioniUtenteTipoFormatoBackupEnum).map((formato) => (
                                            <MenuItem key={formato} value={formato} sx={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }}>
                                                {formato}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            sx={{
                                padding: '10px 20px',
                                fontFamily: '"Montserrat", sans-serif',
                                fontSize: '16px',
                                backgroundColor: '#3f51b5',
                                '&:hover': {
                                    backgroundColor: '#303f9f',
                                },
                            }}
                        >
                            Salva Impostazioni
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Container>
    );
};

export default Configurazioni;
