import React, {useEffect, useState} from 'react';
import {Container} from "@mui/material";
import {ObiettivoRestApi} from "../api";
import ListaObiettivi from "../components/obiettivi/Lista-obiettivi/Lista-obiettivi";
import {fetchObiettivi} from "../store/obiettivi/ObiettiviSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";


const ListaObiettiviPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const obiettivi = useSelector((state: RootState) => state.comments.comments);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);


    const savedUser = localStorage.getItem('user');
    const datiUtente = savedUser ? JSON.parse(savedUser) : null;

    useEffect(() => {
            dispatch(fetchObiettivi({ datiUtente }));
    }, [dispatch]);


    return (
        <div>
            <Container>
                <ListaObiettivi
                    achievements={obiettivi} />
            </Container>
        </div>
    );
};

export default ListaObiettiviPage;
