import React, {useEffect, useState} from 'react';
import GameObjectivesCard from "../components/obiettivi/Obiettivi";
import {Box, Container} from "@mui/material";
import {GetProgressoObiettiviInCorsoRequest, ObiettivoRestApi} from "../api";


const obiettivoApi = new ObiettivoRestApi();
const Home: React.FC = () => {
    const [obiettivi, setObiettivi] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const fetchObiettivi = async () => {
        const utente = localStorage.getItem('user');
        if (!utente) {
            setError('Utente non trovato nel localStorage');
            return;
        }

        const datiUtente = JSON.parse(utente);

        if (!datiUtente.id) {
            setError('ID utente non trovato');
            return;
        }

        const requestData: GetProgressoObiettiviInCorsoRequest = {
            id: datiUtente.id,
        };
        try {
            const response = await obiettivoApi.getProgressoObiettiviInCorsoRaw(requestData);
            const jsonResponse = await response.raw.json();

            if (jsonResponse && jsonResponse.length > 0) {
                setObiettivi(jsonResponse);
            } else {
                setError('Nessun obiettivo trovato');
            }
        } catch (error) {
            console.error('Errore durante il recupero degli obiettivi:', error);
            setError('Errore durante il recupero degli obiettivi');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchObiettivi().then(r => {});
    }, []);

    return (
        <div>
            <h1>Benvenuto su Collectoon</h1>
            <Container>
                <Box display="flex" justifyContent="flex-end" alignItems="flex-start" minHeight="50vh" mt={15}>
                    <GameObjectivesCard objectives={obiettivi} />
                </Box>
            </Container>
        </div>
    );
};

export default Home;

export {};
