/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CollezioneDTO,
  CollezioneInsertDTO,
  CollezioneUpdateDTO,
  Pageable,
  Problem,
  SearchRequest,
} from '../models/index';
import {
    CollezioneDTOFromJSON,
    CollezioneDTOToJSON,
    CollezioneInsertDTOFromJSON,
    CollezioneInsertDTOToJSON,
    CollezioneUpdateDTOFromJSON,
    CollezioneUpdateDTOToJSON,
    PageableFromJSON,
    PageableToJSON,
    ProblemFromJSON,
    ProblemToJSON,
    SearchRequestFromJSON,
    SearchRequestToJSON,
} from '../models/index';

export interface AddCardCollectionRequest {
    collezioneId: string;
    cartaId: string;
    body: number;
}

export interface CollectionByIdRequest {
    collectionId: string;
}

export interface CollectionByIdUtenteRequest {
    userId: string;
}

export interface CreateCollectionRequest {
    collezioneInsertDTO: CollezioneInsertDTO;
}

export interface DeleteCollectionRequest {
    idCollection: string;
}

export interface FindByCriteriaRequest {
    searchRequest: SearchRequest;
}

export interface PubblicCollectionRequest {
    idUser: string;
    pageable: Pageable;
}

export interface QtyCardForUserRequest {
    iduser: string;
}

export interface SaveCoverImageRequest {
    idCollection: string;
    file: Blob;
}

export interface UpdateCollectionRequest {
    collezioneUpdateDTO: CollezioneUpdateDTO;
}

export interface UserCollectionIsPresentRequest {
    iduser: string;
}

/**
 * 
 */
export class CollezioneRestApi extends runtime.BaseAPI {

    /**
     * L\'inserimento richiede obbligatoriamente la card
     * aggiunta di una card alla collezione
     */
    async addCardCollectionRaw(requestParameters: AddCardCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollezioneDTO>> {
        if (requestParameters['collezioneId'] == null) {
            throw new runtime.RequiredError(
                'collezioneId',
                'Required parameter "collezioneId" was null or undefined when calling addCardCollection().'
            );
        }

        if (requestParameters['cartaId'] == null) {
            throw new runtime.RequiredError(
                'cartaId',
                'Required parameter "cartaId" was null or undefined when calling addCardCollection().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling addCardCollection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/collection/add/{collezioneId}/card/{cartaId}`.replace(`{${"collezioneId"}}`, encodeURIComponent(String(requestParameters['collezioneId']))).replace(`{${"cartaId"}}`, encodeURIComponent(String(requestParameters['cartaId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollezioneDTOFromJSON(jsonValue));
    }

    /**
     * L\'inserimento richiede obbligatoriamente la card
     * aggiunta di una card alla collezione
     */
    async addCardCollection(requestParameters: AddCardCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollezioneDTO> {
        const response = await this.addCardCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * cerca una collezione specifica
     */
    async collectionByIdRaw(requestParameters: CollectionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollezioneDTO>> {
        if (requestParameters['collectionId'] == null) {
            throw new runtime.RequiredError(
                'collectionId',
                'Required parameter "collectionId" was null or undefined when calling collectionById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/collection/{collectionId}`.replace(`{${"collectionId"}}`, encodeURIComponent(String(requestParameters['collectionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollezioneDTOFromJSON(jsonValue));
    }

    /**
     * cerca una collezione specifica
     */
    async collectionById(requestParameters: CollectionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollezioneDTO> {
        const response = await this.collectionByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * cerca la lista di collezioni associate all\'utente
     */
    async collectionByIdUtenteRaw(requestParameters: CollectionByIdUtenteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollezioneDTO>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling collectionByIdUtente().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/collection/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollezioneDTOFromJSON(jsonValue));
    }

    /**
     * cerca la lista di collezioni associate all\'utente
     */
    async collectionByIdUtente(requestParameters: CollectionByIdUtenteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollezioneDTO> {
        const response = await this.collectionByIdUtenteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verifica quante collezioni sono state create alla piattaforma
     * conta tutte le collezioni del db
     */
    async countCollectionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollezioneDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/collection/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollezioneDTOFromJSON(jsonValue));
    }

    /**
     * Verifica quante collezioni sono state create alla piattaforma
     * conta tutte le collezioni del db
     */
    async countCollection(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollezioneDTO> {
        const response = await this.countCollectionRaw(initOverrides);
        return await response.value();
    }

    /**
     * L\'inserimento richiede obbligatoriamente il nome e il tipo di card
     * inserimento di una collezione
     */
    async createCollectionRaw(requestParameters: CreateCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollezioneDTO>> {
        if (requestParameters['collezioneInsertDTO'] == null) {
            throw new runtime.RequiredError(
                'collezioneInsertDTO',
                'Required parameter "collezioneInsertDTO" was null or undefined when calling createCollection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/collection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CollezioneInsertDTOToJSON(requestParameters['collezioneInsertDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollezioneDTOFromJSON(jsonValue));
    }

    /**
     * L\'inserimento richiede obbligatoriamente il nome e il tipo di card
     * inserimento di una collezione
     */
    async createCollection(requestParameters: CreateCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollezioneDTO> {
        const response = await this.createCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancella una collezione
     */
    async deleteCollectionRaw(requestParameters: DeleteCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollezioneDTO>> {
        if (requestParameters['idCollection'] == null) {
            throw new runtime.RequiredError(
                'idCollection',
                'Required parameter "idCollection" was null or undefined when calling deleteCollection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/collection/collection/{idCollection}`.replace(`{${"idCollection"}}`, encodeURIComponent(String(requestParameters['idCollection']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollezioneDTOFromJSON(jsonValue));
    }

    /**
     * Cancella una collezione
     */
    async deleteCollection(requestParameters: DeleteCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollezioneDTO> {
        const response = await this.deleteCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di tutte le carte sul db
     */
    async findByCriteriaRaw(requestParameters: FindByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollezioneDTO>> {
        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling findByCriteria().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/collection/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollezioneDTOFromJSON(jsonValue));
    }

    /**
     * L\'inserimento richiede criteria validi
     * Ricerca di tutte le carte sul db
     */
    async findByCriteria(requestParameters: FindByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollezioneDTO> {
        const response = await this.findByCriteriaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * cerca la lista di collezioni associate all\'utente
     */
    async pubblicCollectionRaw(requestParameters: PubblicCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollezioneDTO>> {
        if (requestParameters['idUser'] == null) {
            throw new runtime.RequiredError(
                'idUser',
                'Required parameter "idUser" was null or undefined when calling pubblicCollection().'
            );
        }

        if (requestParameters['pageable'] == null) {
            throw new runtime.RequiredError(
                'pageable',
                'Required parameter "pageable" was null or undefined when calling pubblicCollection().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/collection/public/{idUser}`.replace(`{${"idUser"}}`, encodeURIComponent(String(requestParameters['idUser']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollezioneDTOFromJSON(jsonValue));
    }

    /**
     * cerca la lista di collezioni associate all\'utente
     */
    async pubblicCollection(requestParameters: PubblicCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollezioneDTO> {
        const response = await this.pubblicCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Richiede obbligatoriamente un idUtente
     * Restituisce il totale quantitativo delle carte aggiunte
     */
    async qtyCardForUserRaw(requestParameters: QtyCardForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollezioneDTO>> {
        if (requestParameters['iduser'] == null) {
            throw new runtime.RequiredError(
                'iduser',
                'Required parameter "iduser" was null or undefined when calling qtyCardForUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/collection/verify/card/{iduser}`.replace(`{${"iduser"}}`, encodeURIComponent(String(requestParameters['iduser']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollezioneDTOFromJSON(jsonValue));
    }

    /**
     * Richiede obbligatoriamente un idUtente
     * Restituisce il totale quantitativo delle carte aggiunte
     */
    async qtyCardForUser(requestParameters: QtyCardForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollezioneDTO> {
        const response = await this.qtyCardForUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Carica un\'immagine di copertina per una collezione esistente.
     * Salva l\'immagine di copertina della collezione
     */
    async saveCoverImageRaw(requestParameters: SaveCoverImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollezioneDTO>> {
        if (requestParameters['idCollection'] == null) {
            throw new runtime.RequiredError(
                'idCollection',
                'Required parameter "idCollection" was null or undefined when calling saveCoverImage().'
            );
        }

        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling saveCoverImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/api/collection/{idCollection}/cover`.replace(`{${"idCollection"}}`, encodeURIComponent(String(requestParameters['idCollection']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollezioneDTOFromJSON(jsonValue));
    }

    /**
     * Carica un\'immagine di copertina per una collezione esistente.
     * Salva l\'immagine di copertina della collezione
     */
    async saveCoverImage(requestParameters: SaveCoverImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollezioneDTO> {
        const response = await this.saveCoverImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * restituisce le ultime collezioni pubbliche in ordine decrescente
     */
    async ultimeCollezioniPubblicheRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollezioneDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/collection/recent-collections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollezioneDTOFromJSON(jsonValue));
    }

    /**
     * restituisce le ultime collezioni pubbliche in ordine decrescente
     */
    async ultimeCollezioniPubbliche(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollezioneDTO> {
        const response = await this.ultimeCollezioniPubblicheRaw(initOverrides);
        return await response.value();
    }

    /**
     * aggiornamento di una collezione
     */
    async updateCollectionRaw(requestParameters: UpdateCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollezioneDTO>> {
        if (requestParameters['collezioneUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'collezioneUpdateDTO',
                'Required parameter "collezioneUpdateDTO" was null or undefined when calling updateCollection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/collection`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CollezioneUpdateDTOToJSON(requestParameters['collezioneUpdateDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollezioneDTOFromJSON(jsonValue));
    }

    /**
     * aggiornamento di una collezione
     */
    async updateCollection(requestParameters: UpdateCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollezioneDTO> {
        const response = await this.updateCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Richiede obbligatoriamente un idUtente
     * Verifica la presenza di collezioni di un utente
     */
    async userCollectionIsPresentRaw(requestParameters: UserCollectionIsPresentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollezioneDTO>> {
        if (requestParameters['iduser'] == null) {
            throw new runtime.RequiredError(
                'iduser',
                'Required parameter "iduser" was null or undefined when calling userCollectionIsPresent().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/collection/verify/{iduser}`.replace(`{${"iduser"}}`, encodeURIComponent(String(requestParameters['iduser']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollezioneDTOFromJSON(jsonValue));
    }

    /**
     * Richiede obbligatoriamente un idUtente
     * Verifica la presenza di collezioni di un utente
     */
    async userCollectionIsPresent(requestParameters: UserCollectionIsPresentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollezioneDTO> {
        const response = await this.userCollectionIsPresentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
