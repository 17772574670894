import {CollezioneInsertDTO, CollezioneInsertDTOTipoCardEnum, CollezioneUpdateDTO} from "../../api";
import {AppDispatch} from "../store";
import {addCollection, deleteCollection, updateCollection} from "./CollezioniSlice";
import {toast} from "react-toastify";

export const handleCreateCollection = async (dispatch: AppDispatch, nomeCollezione: string, isPrivata: boolean, tipoCollezione: CollezioneInsertDTOTipoCardEnum | undefined, userId: string | undefined) => {
    const insertDTO: CollezioneInsertDTO = {
        nome: nomeCollezione,
        isPrivate: isPrivata,
        tipoCard: tipoCollezione!,
        utenteId: userId!
    };

    try {
        const resultAction = await dispatch(addCollection(insertDTO));

        if (addCollection.fulfilled.match(resultAction)) {
            toast.success("Collezione Creata con successo!!");
        } else {
            toast.error('Errore nel salvataggio del commento: ' + resultAction.error.message);
        }
    } catch (error) {
        toast.error('Errore nel salvataggio del commento.');
    }
};

export const handleUpdateCollection = async (dispatch: AppDispatch, idCollection: string | undefined, nomeCollezione: string, isPrivata: boolean) => {
    const updateDTO: CollezioneUpdateDTO = {
        id: idCollection,
        nome: nomeCollezione,
        isPrivate: isPrivata,
    };

    try {
        const resultAction = await dispatch(updateCollection(updateDTO));
        if (updateCollection.fulfilled.match(resultAction)) {
            toast.success("Collezione Modificata con successo!!");
        } else {
            toast.error('Errore nel salvataggio del commento: ' + resultAction.error.message);
        }
    } catch (error) {
        toast.error('Errore nel salvataggio del commento.');
    }
}

export const handleDeleteCollection = async(dispatch: AppDispatch, idCollection: string | undefined) =>{
        if (!idCollection) {
            toast.error("ID collezione mancante.");
            return;
        }
    try {
        await dispatch(deleteCollection(idCollection));
    } catch (error) {
        toast.error("Errore durante l'eliminazione della collezione.");
    }


}
