/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SortObject } from './SortObject';
import {
    SortObjectFromJSON,
    SortObjectFromJSONTyped,
    SortObjectToJSON,
} from './SortObject';

/**
 * 
 * @export
 * @interface PageRequestDTO
 */
export interface PageRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof PageRequestDTO
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRequestDTO
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRequestDTO
     */
    offset?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageRequestDTO
     */
    sort?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageRequestDTO
     */
    paged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRequestDTO
     */
    unpaged?: boolean;
}

/**
 * Check if a given object implements the PageRequestDTO interface.
 */
export function instanceOfPageRequestDTO(value: object): value is PageRequestDTO {
    return true;
}

export function PageRequestDTOFromJSON(json: any): PageRequestDTO {
    return PageRequestDTOFromJSONTyped(json, false);
}

export function PageRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageRequestDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'pageNumber': json['pageNumber'] == null ? undefined : json['pageNumber'],
        'pageSize': json['pageSize'] == null ? undefined : json['pageSize'],
        'offset': json['offset'] == null ? undefined : json['offset'],
        'sort': json['sort'] == null ? undefined : SortObjectFromJSON(json['sort']),
        'paged': json['paged'] == null ? undefined : json['paged'],
        'unpaged': json['unpaged'] == null ? undefined : json['unpaged'],
    };
}

export function PageRequestDTOToJSON(value?: PageRequestDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pageNumber': value['pageNumber'],
        'pageSize': value['pageSize'],
        'offset': value['offset'],
        'sort': SortObjectToJSON(value['sort']),
        'paged': value['paged'],
        'unpaged': value['unpaged'],
    };
}

