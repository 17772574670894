import {styled} from "@mui/system";
import {Card, CardContent, Grid, Typography} from "@mui/material";

export const SearchInput = styled('div')({
    width: '100%',
    padding: '10px 15px',
    marginBottom: '20px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    display: 'flex',
    alignItems: 'center',
});

export const Root = styled('div')({
    padding: '20px',
});

export const AchievementGrid = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
});

export const AchievementCard = styled(Card)({
    width: '100%',
    height: 'auto',
    transition: 'transform 0.2s ease',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '18px',
    '&:hover': {
        transform: 'scale(1.05)',
    },
});


export const UnlockedCard = styled(AchievementCard)({
    backgroundColor: '#e0f7fa',
});

export const LockedCard = styled(AchievementCard)({
    backgroundColor: '#fbe9e7',
});

export const CardContentStyled = styled(CardContent)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '8px',
    width: '100%',
});

export const Title = styled(Typography)({
    fontWeight: 'bold',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1rem',
    textAlign: 'left',
    paddingLeft: '10px',
    marginBottom: '5px',
});

export const Description = styled(Typography)({
    textAlign: 'left',
    fontFamily: 'Open Sans, sans-serif',
    paddingLeft: '10px',
    fontSize: '0.75rem',
    color: '#555',
    fontStyle: 'italic',
});

export const DataCompletamento = styled(Typography)({
    marginTop: '5px',
    fontSize: '0.75rem',
    color: '#777',
    textAlign: 'right',
    flex: 1,
});

export const ProgressBarContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
});
