import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {FaEye, FaFileExport, FaImage, FaLock, FaTrash} from 'react-icons/fa';
import {saveAs} from 'file-saver';
import {useDispatch} from 'react-redux';
import {toast} from "react-toastify";
import styles from '../../styles/MyCollectionDetail.module.css';
import {
    CartaDTO,
    CollezioneDTO,
    CollezioneRestApi,
    CommentoCartaRestApi,
    CommentoSearchRequest,
    DocumentiRestApi,
    ExportCollezioneToPDFRequest,
    GetCommentiByCartaRequest,
    UtenteDTO
} from "../../api";
import {transformCardType} from '../../utility/utils';
import CardCollectionList from "../../components/Card/Lista/Card-list-collection";
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import CommentoCollezione from "../../components/CommentoCollezione/Commento-collezione";
import ListaCommenti from "../../components/ListaCommenti/Lista-commenti";
import {MyCollectionDetailProps} from "../../utility/props";
import {uploadImageCover} from "../../store/collezioni/CollezioniSlice";
import {AppDispatch} from "../../store/store";
import {Box, Button, Chip, CircularProgress, Tab, Tabs, Tooltip} from "@mui/material";
import TendenzaPrezzo from "../../components/Grafici/TendenzaPrezzo";


const api = new CollezioneRestApi();
const exportApi = new DocumentiRestApi();
const commentiApi = new CommentoCartaRestApi();


const MyCollectionDetail: React.FC<MyCollectionDetailProps> = ({showExportButton}) => {
    const {idCollection} = useParams<{ idCollection: string | undefined }>();
    const dispatch = useDispatch<AppDispatch>(); // Hook to dispatch actions
    const [collezione, setCollections] = useState<CollezioneDTO | undefined>(undefined);
    const [utente, setUser] = useState<UtenteDTO>();
    const [carte, setCards] = useState<CartaDTO[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [commenti, setCommenti] = useState<any[]>([]);
    const [page, setPage] = useState<number>(1);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
    const location = useLocation();
    const [loadingBackground, setLoadingBackground] = useState<boolean>(false);
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const breadcrumbItems = showExportButton
        ? [
            {name: 'Le Mie collezioni', link: `/my-collection/${utente?.id}`},
            {name: collezione?.nome || 'Collezione'},
        ]
        : [
            {name: 'Collezioni Pubbliche', link: `/collections-user/public`},
            {name: collezione?.nome || 'Collezione'},
        ];

    useEffect(() => {
        const fetchUserCollection = async () => {
            setLoading(true);
            setError(null);
            try {
                if (idCollection !== undefined) {
                    const collectionRequest = {
                        collectionId: idCollection,
                    };

                    const response = await api.collectionByIdRaw(collectionRequest);
                    const jsonResponse = await response.raw.json();

                    setCollections(jsonResponse);
                    setUser(jsonResponse.utente);
                    setCards(jsonResponse.carte);

                    if (jsonResponse.imgCopertina) {
                        setBackgroundImage(`data:image/png;base64,${jsonResponse.imgCopertina}`);
                    } else {
                        setBackgroundImage(null);
                    }
                }
            } catch (error) {
                setError('Failed to fetch collections. Please try again later.');
                console.error('Error fetching collection:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserCollection().then(r => {
        });
    }, [idCollection]);

    useEffect(() => {
        searchComment().then(r => {
        });
    }, [page, idCollection]);

    const handleExport = async () => {
        if (idCollection !== undefined) {
            const collectionRequest: ExportCollezioneToPDFRequest = {
                collezioneId: idCollection,
            };

            try {
                const response = await exportApi.exportCollezioneToPDFRaw(collectionRequest);
                const blob = await response.raw.blob();
                saveAs(blob, `${collezione?.nome}-${idCollection}.pdf`);
            } catch (error) {
                console.error('Error exporting collection to PDF:', error);
            }
        }
    };

    const searchComment = async () => {
        if (!idCollection) return;

        const pageable = {
            pageNumber: page - 1,
            pageSize: 15,
        };

        const commentoSearchRequest: CommentoSearchRequest = {
            idCollezione: idCollection,
            pageable: pageable,
        };

        const apiRequest: GetCommentiByCartaRequest = {
            commentoSearchRequest: commentoSearchRequest,
        };

        try {
            const response = await commentiApi.getCommentiByCartaRaw(apiRequest);
            const data = await response.raw.json();
            setCommenti(data.content || []);
        } catch (error) {
            toast.error('Si è verificato un errore con il caricamento dei commenti.');
        }
    };

    const isPublicCollectionRoute = location.pathname.includes('collections-user-detail');
    const collectionType = isPublicCollectionRoute ? "Collezione Pubblica" : undefined;

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        setSelectedFile(file);
    };

    const handleChangeBackground = async () => {
        if (idCollection && selectedFile) {
            setLoadingBackground(true);
            try {
                const resultAction = await dispatch(uploadImageCover({ idCollection, file: selectedFile }));
                if (uploadImageCover.fulfilled.match(resultAction)) {
                    const imageUrl = URL.createObjectURL(selectedFile);
                    setBackgroundImage(imageUrl);
                    toast.success('Immagine di copertina aggiornata con successo!');
                } else {
                    toast.error('Errore durante l\'aggiornamento dell\'immagine di copertina.');
                }
            } catch (error) {
                toast.error('Si è verificato un errore durante il caricamento dell\'immagine.');
            } finally {
                setLoadingBackground(false);
                setSelectedFile(null);
            }
        } else {
            toast.error('ID collezione o file mancante.');
        }
    };

    const handleRemoveBackground = async () => {
        if (idCollection) {
            setLoadingBackground(true);
            setSelectedFile(null)
            try {
                const resultAction = await dispatch(uploadImageCover({ idCollection, file: selectedFile }));
                if (uploadImageCover.fulfilled.match(resultAction)) {
                    setBackgroundImage(null);
                    toast.success('Immagine di copertina rimossa con successo!');
                }
            } catch (error) {
                toast.error('Si è verificato un errore durante la rimozione dell\'immagine.');
            } finally {
                setLoadingBackground(false);
            }
        } else {
            toast.error('ID collezione mancante.');
        }
    };

    const handleUserClick = () => {
        if (!showExportButton) {
            navigate(`/user-detail/${utente?.id}`);
        }
    };

    return (
        <>
            <Breadcrumb items={breadcrumbItems}/>
            <div className={styles.profileContainer}
                 style={{backgroundImage: `url(${backgroundImage})`, position: 'relative'}}>
                {loading ? (
                    <div className={styles.loadingContainer}>
                        <CircularProgress/>
                    </div>
                ) : (
                    <>
                        {showExportButton && (
                            <div className={styles.changeBackgroundButtonContainer}
                                 style={{position: 'absolute', top: '10px', right: '10px'}}>
                                {selectedFile ? (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleChangeBackground}
                                            disabled={loadingBackground}
                                            startIcon={loadingBackground ?
                                                <CircularProgress size={20} color="inherit"/> : null}
                                            className={styles.customButton}
                                            sx={{
                                                marginTop: '15px',
                                                padding: '8px 10px',
                                                borderRadius: '20px',
                                                fontSize: '0.75rem'
                                            }}
                                        >
                                            {loadingBackground ? 'Caricando...' : 'Carica Immagine'}
                                        </Button>
                                        {backgroundImage && (
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={() => setBackgroundImage(null)}
                                                className={styles.customButton}
                                                sx={{
                                                    fontSize: '0.75rem',
                                                    padding: '8px 10px',
                                                    borderRadius: '20px',
                                                    marginTop: '15px',
                                                    marginLeft: '5px'
                                                }}
                                            >
                                                Elimina Copertina
                                            </Button>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{display: 'none'}}
                                            id="background-upload"
                                            onChange={handleFileChange}
                                        />
                                        <label htmlFor="background-upload">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                component="span"
                                                className={`${styles.selectImageButton} ${styles.customButton}`}
                                                sx={{
                                                    borderRadius: '20px',
                                                    fontSize: '0.75rem',
                                                    marginTop: '15px',
                                                    padding: '8px 10px'
                                                }}
                                            >
                                                <FaImage style={{marginRight: '4px'}}/>
                                                Seleziona Copertina
                                            </Button>
                                        </label>
                                        {backgroundImage && (
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={handleRemoveBackground}
                                                className={styles.customButton}
                                                sx={{
                                                    borderRadius: '20px',
                                                    fontSize: '0.75rem',
                                                    marginTop: '15px',
                                                    padding: '8px 10px',
                                                    marginLeft: '5px'
                                                }}
                                            >
                                                <FaTrash style={{marginRight: '4px'}}/>
                                                Elimina Copertina
                                            </Button>
                                        )}
                                    </>
                                )}
                            </div>
                        )}

                        <div className={styles.profileHeader}>
                            <div className={styles.avatar}></div>
                            <div className={styles.userInfo}>
                                <h1 className={styles.collectionName}>
                                    {collezione?.nome}{" "}
                                </h1>
                                <div className={styles.username}>
                                    <Chip
                                        label={<em>Created by {utente?.username}</em>}
                                        className={styles.chip}
                                        variant="outlined"
                                        onClick={handleUserClick}
                                        sx={{
                                            backgroundColor: '#e0f7fa',
                                            color: '#00796b',
                                            borderColor: '#00796b',
                                            borderWidth: 1,
                                            borderRadius: '20px',
                                            padding: '6px 12px',
                                            cursor: !showExportButton ? 'pointer' : 'default',
                                            pointerEvents: showExportButton ? 'none' : 'auto',
                                            '&:hover': {
                                                backgroundColor: !showExportButton ? '#b2ebf2' : '#e0f7fa',
                                                borderColor: !showExportButton ? '#004d40' : '#00796b',
                                            },
                                        }}
                                    />
                                </div>
                            </div>

                            {showExportButton && (
                                <div className={styles.exportButtonContainer}>
                                    <button className={styles.exportButton} onClick={handleExport}>
                                        <FaFileExport/> Export PDF
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className={styles.statsContainer}>
                            <div className={styles.stats}>
                                <span>{collezione?.mediaValutazioni ?? 'N/A'}</span>
                                <span>VALUTAZIONE MEDIA COLLEZIONE</span>
                            </div>
                            <div className={styles.stats}>
                                <span>{transformCardType(collezione?.tipoCard)}</span>
                                <span>TIPO COLLEZIONE</span>
                            </div>
                            <div className={styles.stats}>
                                <span>{carte.length}</span>
                                <span>TOTALE CARTE UNICHE</span>
                            </div>
                            {showExportButton && (
                                <div className={styles.stats}>
                                    <Tooltip
                                        title={collezione?.isPrivate ? "Collezione Privata" : "Collezione Pubblica"}
                                        arrow>
                <span>
                    {collezione?.isPrivate ? (
                        <FaLock className={styles.icon}/>
                    ) : (
                        <FaEye className={styles.icon}/>
                    )}
                </span>
                                    </Tooltip>
                                    <span>VISIBILITA'</span>
                                </div>
                            )}
                            <div className={styles.stats}>
                                <span>€ {collezione?.valoreCollezione}</span>
                                <span>VALORE</span>
                            </div>
                        </div>

                        <div className={styles.tabContainer}>
                            <Box sx={{ width: '100%', marginTop: '20px' }}>
                                <Tabs value={selectedTab} onChange={handleTabChange} centered>
                                    <Tab label="Lista Carte" />
                                    <Tab
                                        label="Tendenza Valore"
                                        disabled={!showExportButton || !collezione?.tendenzaPrezzo}
                                        sx={{
                                            cursor: (!showExportButton || !collezione?.tendenzaPrezzo) ? 'not-allowed' : 'pointer'
                                        }}
                                    />
                                </Tabs>
                            </Box>
                        </div>

                        {selectedTab === 0 && (
                            <div className={styles.cardGridContainer}>
                                <CardCollectionList
                                    idCollection={idCollection}
                                    cardsCollection={carte}
                                    cardsPerPage={12}
                                    showDeleteIcon={false}
                                    collectionType={collectionType}
                                />
                            </div>
                        )}

                        {selectedTab === 1 && (
                            <div className={styles.cardGridContainer}>
                                <TendenzaPrezzo tendenza={collezione?.tendenzaPrezzo}></TendenzaPrezzo>
                            </div>
                        )}

                        {!showExportButton && (
                            <>
                                <div>
                                    <ListaCommenti idCollection={idCollection}/>
                                </div>
                                <div className={styles.commentSection}>
                                    <CommentoCollezione collezioneId={collezione?.id}/>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    )
};


export default MyCollectionDetail;
